import React, { useState } from 'react';
import '../styles/CreateAd.css';
import GenerateButton from "./GenerateButton";

const CreateImage = ({ onResponse, onLoading }) => {
    const [formData, setFormData] = useState({description: ''});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.description) {
            console.error('Description is required.');
            return;
        }

        console.log("Starting spinner for CreateImage...");
        onLoading("Generating images");

        try {
            console.log("Calling onResponse...");
            await onResponse(formData); // Pass the form data for API call
            console.log("onResponse finished successfully.");
        } catch (error) {
            console.error("Error submitting form:", error);
        }
        // Spinner will be stopped in the `useEffect` of the parent
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Description</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Write your description here..."
                    required
                />
            </div>
            <GenerateButton />
        </form>
    );
};

export default CreateImage;