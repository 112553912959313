import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../config";
import "../styles/Favorites.css";
import countries from "i18n-iso-countries";
import "i18n-iso-countries/langs/en.json";
import ImageLoadingSpinner from "./ImageLoadingSpinner";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Favorites = ({ csrfToken, onFavoriteSelect }) => {
  const [data, setData] = useState({
    content_idea: [],
    hook: [],
    post: [],
    latest_linkedin_post: [],
  });

  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [searchTerm, setSearchTerm] = useState(""); // New search term state
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    if (!dateString) return "No Data";
    try {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.error("Invalid date format:", dateString);
      return "Invalid Date";
    }
  };

  const fetchLatestObjects = useCallback(async () => {
    setIsFavoritesLoading(true);
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      setError("Loading...");
      setIsDataLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/latest-favorites-objects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
        setError(null);
      } else if (response.status === 401) {
        setError("Authorization failed. Please log in again.");
      } else {
        console.error("Failed to fetch data:", response.statusText);
        setError("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setTimeout(() => {
        setIsFavoritesLoading(false);
      }, 100);
      setIsDataLoading(false);
    }
  }, [csrfToken]);

  useEffect(() => {
    fetchLatestObjects();
  }, [fetchLatestObjects]);

  const toggleExpandRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // 🔍 **Filter Rows Based on Search Term**
  const filteredData = Object.entries(data).reduce((acc, [key, values]) => {
    const filteredValues = values.filter((entry) =>
        entry.content.toLowerCase().includes(searchTerm.toLowerCase()) || // Match content
        formatDate(entry.date).toLowerCase().includes(searchTerm.toLowerCase()) // Match date
    );
    return filteredValues.length ? { ...acc, [key]: filteredValues } : acc;
  }, {});
  const handleDelete = async (postId) => {
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this post?")) {
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/latest-favorites-objects/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ post_id: postId }),
      });

      if (response.ok) {
        setData((prevData) => {
          const updatedPosts = prevData.latest_linkedin_post.filter(post => post.id !== postId);
          return { ...prevData, latest_linkedin_post: updatedPosts };
        });
      } else {
        console.error("Failed to delete post:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };


  const renderDocumentsTable = () => (
      <div className="documents-section">
        <>
          {/* 🔍 **Search Input** */}
          <input
              type="text"
              placeholder="Search for a post..."
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
          />

          <table className="documents-table">
            <thead>
            <tr>
              <th>Favorite Posts</th>
              <th>Date Added</th>
              <th>Action</th> {/* New column for the button */}
            </tr>
            </thead>
            {(isDataLoading || isFavoritesLoading) && <ImageLoadingSpinner message="Loading Favorite Posts" />}
            <tbody>
            {Object.entries(filteredData).map(([key, values]) => {
              if (!values || values.length === 0) return null;

              return values.map((entry, index) => {
                const isExpanded = expandedRows[`${key}-${index}`];
                const displayedContent = isExpanded
                    ? entry.content
                    : entry.content?.substring(0, 50) + (entry.content?.length > 50 ? "..." : "");

                return (
                    <tr key={`${key}-${index}`}>
                      <td>
                        <pre>{displayedContent}</pre>
                        {entry.content?.length > 50 && (
                            <span
                                className="view-full"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleExpandRow(`${key}-${index}`);
                                }}
                            >
                          {isExpanded ? " Show Less" : " View Full"}
                        </span>
                        )}
                      </td>
                      <td>{formatDate(entry.date)}</td>
                      <td>
                        <button
                            className="favorite-select-button"
                            onClick={() => onFavoriteSelect(entry.content)}
                        >
                          Generate a post
                        </button>
                        <button
                            className="delete-button"
                            onClick={() => handleDelete(entry.id)}
                        >
                          ❌ Delete
                        </button>
                      </td>

                    </tr>
                );
              });
            })}
            </tbody>
          </table>
        </>
      </div>
  );


  return (
      <div className="dashboard-container">
        {renderDocumentsTable()}
      </div>
  );
};

export default Favorites;