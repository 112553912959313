import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from '../config';
import {toast} from "react-hot-toast";
import login_content from "../assets/login_content.png";
import wizard_image from "../assets/wizard.png";
import {Link, useParams} from "react-router-dom";

const ResetPassword = () => {
    const { uid, token } = useParams();
    const [isValid, setIsValid] = useState(false);

    // Validate the token when the component loads
    useEffect(() => {
        axios
            .get(`${API_URL}/users/reset-password/${uid}/${token}/`) // Update the URL to match your backend route
            .then((response) => {
                console.log(response.data.detail);
                setIsValid(true);
            })
            .catch((error) => {
                toast.error(error.response.data.detail || 'Expired or invalid link', {position: "top-right"});
                toast("Redirecting to login page...", {position: "top-right"})
                // Redirect to login page
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000); // Add a small delay to allow the toast to show
            });
    }, [uid, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const password1 = data.get("password1");
        const password2 = data.get("password2");

        const toastID = toast.loading("Loading...", {position: "top-right"});
        try {
            // Make the POST request
            const response = await axios.post(`${API_URL}/users/reset-password/${uid}/${token}/`,
                { password1: password1, password2: password2 });

            toast.success(response.data.detail, {id: toastID}); // Pass success message to the toast
            toast("Redirecting to login page...", {position: "top-right"})
            // Redirect to login page (or any other route) after success
            setTimeout(() => {
                window.location.href = "/login";
            }, 3000); // Add a small delay to allow the toast to show
        } catch (error) {
            // Handle cases where the request fails (e.g., 404, server error)
            if (error.response) {
                // Server responded with an error status code
                toast.error(error.response.data.detail, {id: toastID});
            } else if (error.request) {
                // Request made but no response received
                toast.error("No response from the server. Please try again.", {id: toastID});
            } else {
                // Setup or unexpected error
                toast.error("An unexpected error occurred. Please try again.", {id: toastID});
            }
        }
    };

    return (
        <div className="login-page">
            <div className="login-left">
                <div className="login-left-content">
                    <Link to="/">
                        <img
                            src={login_content}
                            alt="Brand Wizard Illustration"
                            className="login-left-image"
                        />
                    </Link>
                    <h1>Brand Wizard</h1>
                    <p>
                        Your AI marketing magic: strategies & tools, crafted from your data
                        for your audience.
                    </p>
                </div>
            </div>
            <div className="login-right">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-header">
                        <Link to="/">
                            <img
                                src={wizard_image}
                                alt="Wizard Icon"
                                className="login-header-icon"
                            />
                        </Link>
                        <h2>Password Change</h2>
                        <p>Please select a new password for your account</p>
                    </div>

                    <div className="input-group">
                        <input
                            type="password"
                            name="password1"
                            className="login-input"
                            placeholder="Type your Password"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <input
                            type="password"
                            name="password2"
                            className="login-input"
                            placeholder="Retype your Password"
                            required
                        />
                    </div>

                    <button className="login-button" type="submit" disabled={!isValid}>
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;