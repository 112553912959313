import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/CreateAd.css'; // Ensure you have styles for loading dots in your CSS
import { API_URL } from '../config';
import KeywordsIdeas from "./KeywordsIdeas";
import KeywordsSuggestions from "./KeywordsSuggestions";
import CompetitorsKeywords from "./CompetitorsKeywords";
import LinkedInForm from "./LinkedInForm";


const CreateEmail = ({ onResponse, onLoading, onSetRequestCount, onKeywordsFetched }) => {
    const [formData, setFormData] = useState({
        type: '',
        language: '',
        brand_voice: '',
        emotion: '',
        tone: '',
        goal: '',
        target_audience: '',
        formula: '',
        creativity: '',
        use_emoji: '',
        use_hashtags: false,
        contact_first_name: '',
        contact_description: '',
        prompt: '',
        call_to_action: '',
        keywords: '',
        models: [],
        selectedProducts: [], // Initialize as empty array
        selectedKnowledge: [], // Initialize as empty array
    });
    const [products, setProducts] = useState([]);
    const [knowledge, setKnowledge] = useState([]);
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isKnowledgeOpen, setIsKnowledgeOpen] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [filteredKnowledge, setFilteredKnowledge] = useState(knowledge);
    const [initialBrandVoice, setInitialBrandVoice] = useState("");
    const [initialTargetAudience, setInitialTargetAudience] = useState("");
    const [csrfToken, setCsrfToken] = useState('');
    const [emailTypeChoices, setEmailTypeChoices] = useState([]);
    const [languageTypeChoices, setLanguageTypeChoices] = useState([]);
    const [brandTypeChoices, setBrandTypeChoices] = useState([]);
    const [emotionTypeChoices, setEmotionTypeChoices] = useState([]);
    const [toneTypeChoices, setToneTypeChoices] = useState([]);
    const [goalTypeChoices, setGoalTypeChoices] = useState([]);
    const [targetTypeChoices, setTargetTypeChoices] = useState([]);
    const [formulaTypeChoices, setFormulaTypeChoices] = useState([]);
    const [creativityTypeChoices, setCreativityTypeChoices] = useState([]);
    const [requestCount, setRequestCount] = useState(1);
    const [modelChoices, setModelChoices] = useState([]);
    // State to manage collapsible sections
    const [isAudienceMessagingOpen, setIsAudienceMessagingOpen] = useState(false);
    const [isContentStrategyOpen, setIsContentStrategyOpen] = useState(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
    const [isKeywordsIdeasOpen, setIsKeywordsIdeasOpen] = useState(false);
    const [isKeywordsSuggestionsOpen, setIsKeywordsSuggestionsOpen] = useState(false);
    const [isKeywordsCompetitorsOpen, setIsKeywordsCompetitorsOpen] = useState(false);

    useEffect(() => {
const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(`${API_URL}/users/profile/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const brandVoice = response.data.brand_voice_name || "";
      const targetAudienceName = response.data.target_audience_name || "";

      // Store the initial values separately
      setInitialBrandVoice(brandVoice);
      setInitialTargetAudience(targetAudienceName);

      // Update the formData state
      setFormData((prev) => ({
        ...prev,
        brand_voice: brandVoice,
        target_audience: targetAudienceName,
      }));
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };



  fetchUserProfile();
}, []);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/csrf-token/`);
                setFormData((prev) => ({ ...prev, csrfToken: response.data.csrfToken }));
            } catch (error) {
                console.error("Error fetching CSRF token:", error);
            }
        };

        const fetchResources = async () => {
            try {
                const token = localStorage.getItem("access_token");
                const [productsRes, knowledgeRes] = await Promise.all([
                    axios.get(`${API_URL}/marketing_content/api/products/`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${API_URL}/marketing_content/api/knowledge/`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);
                setProducts(productsRes.data);
                setKnowledge(knowledgeRes.data);
            } catch (error) {
                console.error("Error fetching resources:", error);
            }
        };

        fetchCsrfToken();
        fetchResources();
    }, []);

    // Fetch options for select fields
    useEffect(() => {
        const fetchChoices = async (endpoint, setState) => {
            try {
                // Retrieve the token from localStorage or any other secure storage
                const token = localStorage.getItem('access_token');

                const response = await axios.get(`${API_URL}/marketing_content/api/${endpoint}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Authorization header
                    }
                });
                setState(response.data);
            } catch (error) {
                console.error(`Error fetching ${endpoint}:`, error);
            }
        };

        fetchChoices('dm-types', setEmailTypeChoices);
        fetchChoices('language-types', setLanguageTypeChoices);
        fetchChoices('brand-types', setBrandTypeChoices);
        fetchChoices('emotion-types', setEmotionTypeChoices);
        fetchChoices('dm-tone', setToneTypeChoices);
        fetchChoices('goal-types', setGoalTypeChoices);
        fetchChoices('target-types', setTargetTypeChoices);
        fetchChoices('formula-types', setFormulaTypeChoices);
        fetchChoices('creativity-types', setCreativityTypeChoices);
        fetchChoices('metrics-choices', setModelChoices);
    }, []);
    const filterItems = (type, query) => {
  if (type === "products") {
    setFilteredProducts(
      products.filter((product) =>
        product.product_name.toLowerCase().includes(query.toLowerCase())
      )
    );
  } else if (type === "knowledge") {
    setFilteredKnowledge(
      knowledge.filter((item) =>
        item.knowledge_name.toLowerCase().includes(query.toLowerCase())
      )
    );
  }
};

useEffect(() => {
  setFilteredProducts(products);
  setFilteredKnowledge(knowledge);
}, [products, knowledge]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const toggleSection = (section) => {
  if (section === "products") {
    setIsProductsOpen((prev) => {
      if (!prev) {
        document.getElementById("products-container")?.scrollIntoView({ behavior: "smooth" });
      }
      return !prev;
    });
    setIsKnowledgeOpen(false); // Close Knowledge
  } else if (section === "knowledge") {
    setIsKnowledgeOpen((prev) => {
      if (!prev) {
        document.getElementById("knowledge-container")?.scrollIntoView({ behavior: "smooth" });
      }
      return !prev;
    });
    setIsProductsOpen(false); // Close Products
  }
};

    const toggleSelection = (type, id) => {
        setFormData((prev) => {
            const selectedItems = new Set(prev[type]);
            if (selectedItems.has(id)) {
                selectedItems.delete(id);
            } else {
                selectedItems.add(id);
            }
            return { ...prev, [type]: Array.from(selectedItems) };
        });
    };


    const handleKeywordsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.keywordsData.ideas; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};

const handleKeywordsSuggestionsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.suggestionsData.suggestions; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};

const handleCompetitorsKeywordsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.competitorsData.competitors_keywords; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};
    const handleModelChange = (e) => {
        const { options } = e.target;
        const selectedModels = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);
        setFormData({ ...formData, models: selectedModels });
    };

    const handleRequestCountChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setRequestCount(value);
        if (typeof onSetRequestCount === 'function') {
            onSetRequestCount(value);
        } else {
            console.warn('onSetRequestCount is not defined or is not a function');
        }
    };

    const validateFormData = () => {
        const requiredFields = [];
        for (let field of requiredFields) {
            if (!formData[field]) {
                console.error(`Error: ${field} is required.`);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateFormData()) {
            console.error('Please fill in all required fields.');
            return;
        }
        onLoading("Generating DMs");
        onResponse(formData);
    };

    return (
        <LinkedInForm formData={formData} handleSubmit={handleSubmit} handleChange={handleChange} languageTypeChoices={languageTypeChoices}>
            <div>
                <label>Contact's First Name</label>
                <input
                    className="contant-name"
                    type="text"
                    name="contact_first_name"
                    value={formData.contact_first_name}
                    onChange={handleChange}
                    placeholder="Write contact's First Name..."
                    required
                />
            </div>
            <div>
                <label>Few details about the contact</label>
                <textarea
                    name="contact_description"
                    value={formData.contact_description}
                    onChange={handleChange}
                    placeholder="Write few details about your contact..."
                    required
                />
            </div>
            <div>
                <label>Main message details</label>
                <textarea
                    name="prompt"
                    value={formData.prompt}
                    onChange={handleChange}
                    placeholder="Write your instructions here..."
                    required
                />
            </div>
            {/*<div className="button-row">*/}
            {/*    <button type="button" onClick={() => toggleSection("products")}>Products</button>*/}
            {/*    <button type="button" onClick={() => toggleSection("knowledge")}>Knowledge</button>*/}
            {/*</div>*/}

            {isProductsOpen && (
                <div className="scrollable-container" id="products-container">
                    <div className="section">
                        <h4>My Products:</h4>
                        <div className="search-box-wrapper">
                            <input
                                type="text"
                                placeholder="Search Products..."
                                className="search-box"
                                onChange={(e) => filterItems("products", e.target.value)}
                            />
                        </div>
                        <div className="scrollable-section">
                            <ul className="item-list">
                                {filteredProducts.map((product) => (
                                    <li key={product.id} className="item">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="custom-checkbox"
                                                checked={formData.selectedProducts.includes(product.id)}
                                                onChange={() => toggleSelection("selectedProducts", product.id)}
                                            />
                                            {product.product_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {isKnowledgeOpen && (
                <div className="scrollable-container" id="knowledge-container">
                    <div className="section">
                        <h4>My Knowledge:</h4>
                        <div className="search-box-wrapper">
                            <input
                                type="text"
                                placeholder="Search Knowledge..."
                                className="search-box"
                                onChange={(e) => filterItems("knowledge", e.target.value)}
                            />
                        </div>
                        <div className="scrollable-section">
                            <ul className="item-list">
                                {filteredKnowledge.map((item) => (
                                    <li key={item.id} className="item">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="custom-checkbox"
                                                checked={formData.selectedKnowledge.includes(item.id)}
                                                onChange={() => toggleSelection("selectedKnowledge", item.id)}
                                            />
                                            {item.knowledge_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            <div>
                <label>Type of Relationship</label>
                <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                >
                    <option value="" disabled>New Connections</option>
                    {emailTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label>Tone of Voice</label>
                <select
                    name="type"
                    value={formData.tone}
                    onChange={handleChange}
                >
                    <option value="" disabled>Balanced</option>
                    {toneTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label>Message Goal</label>
                <select
                    name="goal"
                    value={formData.goal}
                    onChange={handleChange}
                >
                    <option value="" disabled>Build a Relationship</option>
                    {goalTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            {/*<div>*/}
            {/*    <label>Select Models</label>*/}
            {/*    <select*/}
            {/*        name="models"*/}
            {/*        multiple*/}
            {/*        value={formData.models}*/}
            {/*        onChange={handleModelChange}*/}
            {/*    >*/}
            {/*        {modelChoices.map((choice) => (*/}
            {/*            <option key={choice.value} value={choice.value}>*/}
            {/*                {choice.label}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}

            {/* Audience & Messaging Section */}
            {/*<div className="section">*/}
            {/*    <div className="section-header" onClick={() => setIsAudienceMessagingOpen(!isAudienceMessagingOpen)}>*/}
            {/*        Audience & Messaging <span>{isAudienceMessagingOpen ? '-' : '+'}</span>*/}
            {/*    </div>*/}
            {/*    {isAudienceMessagingOpen && (*/}
            {/*        <div className="section-content">*/}
            {/*            <div>*/}
            {/*                <label>Brand Voice</label>*/}
            {/*                <select*/}
            {/*                    name="brand_voice"*/}
            {/*                    value={formData.brand_voice}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>Select Brand Voice</option>*/}

            {/*                    /!* Add the initial brand voice *!/*/}
            {/*                    {initialBrandVoice &&*/}
            {/*                        !brandTypeChoices.some((choice) => choice.value === initialBrandVoice) && (*/}
            {/*                            <option key="initial-brand-voice" value={initialBrandVoice}>*/}
            {/*                                {initialBrandVoice}*/}
            {/*                            </option>*/}
            {/*                        )}*/}

            {/*                    /!* Render all other options *!/*/}
            {/*                    {brandTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}


            {/*            <div>*/}
            {/*                <label>Target Audience</label>*/}
            {/*                <select*/}
            {/*                    name="target_audience"*/}
            {/*                    value={formData.target_audience}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>*/}
            {/*                        Select Target Audience*/}
            {/*                    </option>*/}

            {/*                    /!* Add the initial target audience if it is not in the choices *!/*/}
            {/*                    {initialTargetAudience &&*/}
            {/*                        !targetTypeChoices.some((choice) => choice.value === initialTargetAudience) && (*/}
            {/*                            <option key="initial-target-audience" value={initialTargetAudience}>*/}
            {/*                                {initialTargetAudience}*/}
            {/*                            </option>*/}
            {/*                        )}*/}

            {/*                    /!* Render the rest of the dropdown options *!/*/}
            {/*                    {targetTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>Emotion</label>*/}
            {/*                <select*/}
            {/*                    name="emotion"*/}
            {/*                    value={formData.emotion}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>Select Emotion</option>*/}
            {/*                    {emotionTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>Tone</label>*/}
            {/*                <select*/}
            {/*                    name="tone"*/}
            {/*                    value={formData.tone}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>Select Tone</option>*/}
            {/*                    {toneTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*/!* Content Strategy Section *!/*/}
            {/*<div className="section">*/}
            {/*    <div className="section-header" onClick={() => setIsContentStrategyOpen(!isContentStrategyOpen)}>*/}
            {/*        Content Strategy <span>{isContentStrategyOpen ? '-' : '+'}</span>*/}
            {/*    </div>*/}
            {/*    {isContentStrategyOpen && (*/}
            {/*        <div className="section-content">*/}
            {/*            <div>*/}
            {/*                <label>Formula</label>*/}
            {/*                <select*/}
            {/*                    name="formula"*/}
            {/*                    value={formData.formula}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>Select Formula</option>*/}
            {/*                    {formulaTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>Call to Action</label>*/}
            {/*                <input*/}
            {/*                    name="call_to_action"*/}
            {/*                    value={formData.call_to_action}*/}
            {/*                    onChange={handleChange}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>Keywords (optional)</label>*/}
            {/*                <input*/}
            {/*                    name="keywords"*/}
            {/*                    value={formData.keywords}*/}
            {/*                    onChange={handleChange}*/}
            {/*                />*/}
            {/*                <div className="section">*/}
            {/*                    <div className="section-header"*/}
            {/*                         onClick={() => setIsKeywordsIdeasOpen(!isKeywordsIdeasOpen)}>*/}
            {/*                        Generate Keywords ideas<span>{isKeywordsIdeasOpen ? '-' : '+'}</span>*/}
            {/*                    </div>*/}
            {/*                    {isKeywordsIdeasOpen && (*/}
            {/*                        <KeywordsIdeas onFetchDataComplete={handleKeywordsFetch} csrfToken={csrfToken}/>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*                <div className="section">*/}
            {/*                    <div className="section-header"*/}
            {/*                         onClick={() => setIsKeywordsSuggestionsOpen(!isKeywordsSuggestionsOpen)}>*/}
            {/*                        Generate Keywords suggestions<span>{isKeywordsSuggestionsOpen ? '-' : '+'}</span>*/}
            {/*                    </div>*/}
            {/*                    {isKeywordsSuggestionsOpen && (*/}
            {/*                        <KeywordsSuggestions onFetchDataComplete={handleKeywordsSuggestionsFetch}*/}
            {/*                                             csrfToken={csrfToken}/>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*                <div className="section">*/}
            {/*                    <div className="section-header"*/}
            {/*                         onClick={() => setIsKeywordsCompetitorsOpen(!isKeywordsCompetitorsOpen)}>*/}
            {/*                        Find competitors keywords<span>{isKeywordsCompetitorsOpen ? '-' : '+'}</span>*/}
            {/*                    </div>*/}
            {/*                    {isKeywordsCompetitorsOpen && (*/}
            {/*                        <CompetitorsKeywords onFetchDataComplete={handleCompetitorsKeywordsFetch}*/}
            {/*                                             csrfToken={csrfToken}/>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*/!* Preferences Section *!/*/}
            {/*<div className="section">*/}
            {/*    <div className="section-header" onClick={() => setIsPreferencesOpen(!isPreferencesOpen)}>*/}
            {/*        Preferences <span>{isPreferencesOpen ? '-' : '+'}</span>*/}
            {/*    </div>*/}
            {/*    {isPreferencesOpen && (*/}
            {/*        <div className="section-content">*/}
            {/*            <div>*/}
            {/*                <label>Creativity</label>*/}
            {/*                <select*/}
            {/*                    name="creativity"*/}
            {/*                    value={formData.creativity}*/}
            {/*                    onChange={handleChange}*/}
            {/*                >*/}
            {/*                    <option value="" disabled>Select Creativity</option>*/}
            {/*                    {creativityTypeChoices.map((choice) => (*/}
            {/*                        <option key={choice.value} value={choice.value}>*/}
            {/*                            {choice.label}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>*/}
            {/*                    <input*/}
            {/*                        type="checkbox"*/}
            {/*                        name="use_emoji"*/}
            {/*                        checked={formData.use_emoji}*/}
            {/*                        onChange={handleChange}*/}
            {/*                    />*/}
            {/*                    Use Emoji*/}
            {/*                </label>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label>*/}
            {/*                    <input*/}
            {/*                        type="checkbox"*/}
            {/*                        name="use_hashtags"*/}
            {/*                        checked={formData.use_hashtags}*/}
            {/*                        onChange={handleChange}*/}
            {/*                    />*/}
            {/*                    Use Hashtags*/}
            {/*                </label>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/* Number of Requests Field */}
            {/* Number of Requests Field */}
            {/*<div>*/}
            {/*    <label>Number of Requests</label>*/}
            {/*    <select*/}
            {/*        name="request_count"*/}
            {/*        value={requestCount}*/}
            {/*        onChange={(e) => {*/}
            {/*            const value = parseInt(e.target.value, 10);*/}
            {/*            setRequestCount(value);*/}

            {/*            // Notify the parent component if the prop exists*/}
            {/*            if (typeof onSetRequestCount === 'function') {*/}
            {/*                onSetRequestCount(value);*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <option value="1">1</option>*/}
            {/*        <option value="3">3</option>*/}
            {/*        <option value="5">5</option>*/}
            {/*    </select>*/}
            {/*</div>*/}

        </LinkedInForm>
    );
};

export default CreateEmail;
