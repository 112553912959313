import { useState } from "react";

/**
 * A custom hook for managing boolean state with toggle functionality.
 * @param {boolean} initialValue - The initial value of the boolean state.
 * @returns {[boolean, function]} - Returns the current value and a function to toggle it.
 */
export function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);
  const toggle = () => setValue((prev) => !prev);
  return [value, toggle];
}
