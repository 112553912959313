import React from 'react';
import '../styles/GenerateButton.css';
import sparksIcon from "../assets/ai_sparks.svg";

const GenerateButton = ({disabled=false}) => {
  return (
    <div className="generate-button-wrapper">
      <button type="submit" className="generate-button" disabled={disabled}>
          <img src={sparksIcon} className='sparks'/>
          Generate
      </button>
    </div>
  );
};

export default GenerateButton;