import React, { useState, useEffect } from 'react';
import { API_URL } from "../config";
import '../styles/BrandVoice.css';
import ImageLoadingSpinner from "./ImageLoadingSpinner"; // Import CSS styles

const BrandVoice = ({ csrfToken }) => {
    const [brandVoice, setBrandVoice] = useState({
      first_name: '',
      last_name: '',
      role: '',
      job_level: '',
      company_industry: '',
      company_name: '',
      job_title: '',
      language: '',
      country: '',
      goal:'',
      profile_description: '',
      target_audience_description: '',
      tone_of_voice: '',
      industry_topics: '',
      industry_keywords: '',
      gender: '',
      year_of_birth: '',
      hobbies: '',
      ambitions: '',
      personality_description: '',
      tone_reflection: '',
      comments_reflections: []
    });

    const [loading, setLoading] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const toneOptions = [
    "Persuasive",
    "Analytical",
    "Friendly",
    "Thought-Provoking",
    "Conversational",
    "Thought Leadership",
    "Inspirational",
    "Professional",
    "Empathetic",
    "Casual",
    "Witty",
    "Educational",
  ];
  const IndustryTopics = [
      "Accommodation Services",
      "Administrative and Support Services",
      "Construction",
      "Consumer Services",
      "Education",
      "Entertainment Providers",
      "Farming || Ranching || Forestry",
      "Financial Services",
      "Government Administration",
      "Holding Companies",
      "Hospitals and Health Care",
      "Manufacturing",
      "Oil || Gas || Mining",
      "Professional Services",
      "Real Estate and Equipment Rental Services",
      "Retail",
      "Technology || Information || Media",
      "Transportation || Logistics || Supply Chain || Storage",
      "Utilities",
      "Wholesale"
  ];

  const [selectedTone, setSelectedTone] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [painPoints, setPainPoints] = useState([]);
  const [Keywords, setKeywords] = useState([]);
  const [newPainPoint, setNewPainPoint] = useState(""); // To handle input for new pain points
  const [newKeyword, setNewKeyword] = useState(""); // To handle input for new pain points

const toggleTone = (tone) => {
  if (selectedTone.includes(tone)) {
    setSelectedTone(selectedTone.filter((item) => item !== tone));
  } else if (selectedTone.length < 2) {
    setSelectedTone([...selectedTone, tone]);
  }
};

const toggleTopic = (topic) => {
  if (selectedTopic.includes(topic)) {
    setSelectedTopic(selectedTopic.filter((item) => item !== topic));
  } else if (selectedTopic.length < 3) {
    setSelectedTopic([...selectedTopic, topic]);
  }
};


  const addNewKeyword = () => {
    if (newKeyword.trim() !== "") {
      setKeywords((prev) => [...prev, newKeyword]);
      setNewKeyword(""); // Reset input
    }
  };
  const removeKeyword = (Keyword) => {
    setKeywords((prev) => prev.filter((item) => item !== Keyword));
  };


  useEffect(() => {
    const fetchBrandVoice = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token || !csrfToken) {
          console.error('Missing required tokens: Authorization or CSRF.');
          return;
        }

        const response = await fetch(`${API_URL}/users/update-brand-voice/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
        });

      if (response.ok) {
        const data = await response.json();

        // Ensure tone_of_voice and industry_topics are arrays
        setSelectedTone(data.tone_of_voice || []);
        setSelectedTopic(data.industry_topics || []);

        // Handle industry_keywords, splitting the string by commas and trimming each keyword
        setKeywords(
          data.industry_keywords
            ? data.industry_keywords.split(',').map((keyword) => keyword.trim())
            : [] // Default to an empty array if null/undefined
        );

          setBrandVoice({
          ...data,
          comments_reflections: Array.isArray(data.comments_reflections) ? data.comments_reflections : []
        });
      }
      else {
          console.error('Failed to fetch brand voice:', response.status, response.statusText);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching brand voice:', error);
      }
    };

    fetchBrandVoice();
  }, [csrfToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandVoice({
      ...brandVoice,
      [name]: value,
    });
  };

  const handleWordLimitChange = (e) => {
  const inputText = e.target.value;
  const words = inputText.trim().split(/\s+/); // Split by spaces, handling multiple spaces
  if (words.length <= 50) { // Allow up to 50 words
    handleChange(e); // Update form data only if within limit
  }
};
  const handleWordLimitChangePost = (e) => {
  const inputText = e.target.value;
  const words = inputText.trim().split(/\s+/); // Split by spaces, handling multiple spaces
  if (words.length <= 200) { // Allow up to 50 words
    handleChange(e); // Update form data only if within limit
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('access_token');
      if (!token || !csrfToken) {
        console.error('Missing required tokens: Authorization or CSRF.');
        return;
      }
      const payload = { ...brandVoice, tone_of_voice: selectedTone, industry_topics: selectedTopic, industry_keywords: Keywords.join(", ") };
      const response = await fetch(`${API_URL}/users/update-brand-voice/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Brand Voice updated successfully!');
      } else {
        console.error('Failed to update brand voice:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error updating brand voice:', error);
    }
  };
const handleAddComment = () => {
  if (brandVoice.comments_reflections.length < 5) {
    setBrandVoice({
      ...brandVoice,
      comments_reflections: [...brandVoice.comments_reflections, ""], // Add empty comment field
    });
  }
};

const handleCommentChange = (index, value) => {
  const updatedComments = [...brandVoice.comments_reflections];
  updatedComments[index] = value;
  setBrandVoice({ ...brandVoice, comments_reflections: updatedComments });
};
const handleRemoveComment = (index) => {
  const updatedComments = brandVoice.comments_reflections.filter((_, i) => i !== index);
  setBrandVoice({ ...brandVoice, comments_reflections: updatedComments });
};
  const handleGenerateWithAI = async () => {
    if (!brandVoice.company_url.trim()) {
      alert('Please provide a Company URL.');
      return;
    }

    setIsGenerating(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token || !csrfToken) {
        console.error('Missing required tokens: Authorization or CSRF.');
        setIsGenerating(false);
        return;
      }

      const response = await fetch(`${API_URL}/marketing_research/api/brand-mission/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({ company_url: brandVoice.company_url }),
      });

      if (response.ok) {
        const brandMission = await response.text();
        setBrandVoice((prevState) => ({
          ...prevState,
          brand_mission: brandMission,
        }));
      } else {
        console.error('Failed to generate brand mission:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error generating brand mission:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  if (loading) {
    return <ImageLoadingSpinner message="Loading Personalization form" />;
  }

  return (
      <form onSubmit={handleSubmit} className="brand-voice-form">
        <div className="form-block">
          <h2>Core Personalization</h2>
          <p>These details enable us to customize content creation specifically for you, your industry, and your
            goals.</p>
          <div className="name-row">
            <div className="form-group">
              <label>First Name:</label>
              <div className="input-with-button">
                <input
                    type="text"
                    name="first_name"
                    value={brandVoice.first_name || ''}
                    onChange={handleChange}
                    placeholder="First Name"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <div className="input-with-button">
                <input
                    type="text"
                    name="last_name"
                    value={brandVoice.last_name || ''}
                    onChange={handleChange}
                    placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div className="name-row">
            <div className="form-group">
              <label>Company name:</label>
              <div className="input-with-button">
                <input
                    type="text"
                    name="company_name"
                    value={brandVoice.company_name || ''}
                    onChange={handleChange}
                    placeholder="Company Name"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Job Title:</label>
              <div className="input-with-button">
                <input
                    type="text"
                    name="job_title"
                    value={brandVoice.job_title || ''}
                    onChange={handleChange}
                    placeholder="Job title"
                />
              </div>
            </div>
          </div>
          <div className="name-row">
            <div className="form-group">
              <label>Country:</label>
              <div className="input-with-button">
                <input
                    type="text"
                    name="country"
                    value={brandVoice.country || ''}
                    onChange={handleChange}
                    placeholder="Country"
                />
              </div>
            </div>
            <div className="form-group">
              <label>Language:</label>
              <div className="input-with-button">
                <select
                    name="language"
                    value={brandVoice.language || ''}
                    onChange={handleChange}
                    className="language-select"
                >
                  <option value="">Choose your language</option>
                  <option value="English (American)">English (American)</option>
                  <option value="Vietnamese">Vietnamese</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Ukrainian">Ukrainian</option>
                  <option value="Turkish">Turkish</option>
                  <option value="Thai">Thai</option>
                  <option value="Spanish (Spain)">Spanish (Spain)</option>
                  <option value="Spanish (Latin America)">Spanish (Latin America)</option>
                  <option value="Russian">Russian</option>
                  <option value="Portuguese (Portugal)">Portuguese (Portugal)</option>
                  <option value="Portuguese (Brazilian)">Portuguese (Brazilian)</option>
                  <option value="Korean">Korean</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Indonesian">Indonesian</option>
                  <option value="Malay">Malay</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Hebrew">Hebrew</option>
                  <option value="Greek">Greek</option>
                  <option value="French (France)">French (France)</option>
                  <option value="Dutch">Dutch</option>
                  <option value="Chinese (Simplified)">Chinese (Simplified)</option>
                  <option value="Chinese (Traditional)">Chinese (Traditional)</option>
                  <option value="Bengali">Bengali</option>
                </select>
              </div>
            </div>
          </div>
          <div className="name-row">
            <div className="form-group">
              <label>Your Role:</label>
              <div className="input-with-button">
                <select
                    name="role"
                    value={brandVoice.role || ''}
                    onChange={handleChange}
                    className="role-select"
                >
                  <option value="" disabled>Select your role</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Design">Design</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Finance">Finance</option>
                  <option value="Product">Product</option>
                  <option value="Sales">Sales</option>
                  <option value="Customer Support">Customer Support</option>
                  <option value="Customer Success">Customer Success</option>
                  <option value="HR">HR</option>
                  <option value="Project Management">Project Management</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label>Your Job Level:</label>
              <div className="input-with-button">
                <select
                    name="job_level"
                    value={brandVoice.job_level || ''}
                    onChange={handleChange}
                    className="job-level-select"
                >
                  <option value="" disabled>Select your job level</option>
                  <option value="C-Level">C-Level</option>
                  <option value="VP or Executive">VP or Executive</option>
                  <option value="Director">Director</option>
                  <option value="Team Leader">Team Leader</option>
                  <option value="Manager">Manager</option>
                  <option value="Senior">Senior</option>
                  <option value="Junior">Junior</option>
                  <option value="Owner/Founder">Owner/Founder</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div className="name-row">
            <div className="form-group">
              <label>Your Industry:</label>
              <div className="input-with-button">
                <select
                    name="company_industry"
                    value={brandVoice.company_industry || ''}
                    onChange={handleChange}
                    className="company-industry-select"
                >
                  <option value="">Choose your industry</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Retail">Retail</option>
                  <option value="Education">Education</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Energy">Energy</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Construction">Construction</option>
                  <option value="Legal">Legal</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Telecommunications">Telecommunications</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Food & Beverage">Food & Beverage</option>
                  <option value="Aerospace">Aerospace</option>
                  <option value="Public Sector">Public Sector</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label>Your Goal:</label>
              <div className="input-with-button">
                <select
                    name="goal"
                    value={brandVoice.goal || ''}
                    onChange={handleChange}
                    className="goal-select"
                >
                  <option value="">Choose your goal</option>
                  <option value="Build a personal brand">Build a personal brand</option>
                  <option value="Generate leads">Generate leads</option>
                  <option value="Promote my business">Promote my business</option>
                  <option value="Networking">Networking</option>
                  <option value="Find a job">Find a job</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="form-block">
          <h2>Professional Personalization</h2>
          <p>With these details, we can create content tailored to your expertise, target audience, and desired tone of
            voice..</p>
          <div className="name-row">
            <div className="form-group">
              <label>Describe yourself professionally (up to 50 words):</label>
              <small>{brandVoice.profile_description ? brandVoice.profile_description.trim().split(/\s+/).length : 0}/50
                words</small>
              <textarea
                  name="profile_description"
                  value={brandVoice.profile_description || ''}
                  onChange={handleWordLimitChange}
                  placeholder="Write your description"
              />

            </div>
            <div className="form-group">
              <label>Describe your main target audience (up to 50 words):</label>
              <small>
                {brandVoice.target_audience_description ? brandVoice.target_audience_description.trim().split(/\s+/).length : 0}/50
                words
              </small>
              <textarea
                  name="target_audience_description"
                  value={brandVoice.target_audience_description || ''}
                  onChange={handleWordLimitChange}
                  placeholder="Write your description"
              />
            </div>
          </div>
          <div className="options-block">
            <h3>Choose your industry keywords:</h3>

            <div className="input-group-row">
              <input
                  type="text"
                  className="signup-input"
                  placeholder="Add new Keyword"
                  value={newKeyword}
                  onChange={(e) => setNewKeyword(e.target.value)}
              />
              <button className="add-button" onClick={addNewKeyword}>
                Add
              </button>
            </div>

            <div className="options-container">
              {Keywords.map((keyword, index) => (
                  <div key={index} className="option-item">
                    {keyword}
                    <button
                        className="remove-button"
                        onClick={() => removeKeyword(keyword)}
                    >
                      ✖
                    </button>
                  </div>
              ))}
            </div>
          </div>

          <div className="tone-container">
            <h3>Choose your industry topics (up to 3) to:</h3>
            <div className="tone-options">
              {IndustryTopics.map((option) => (
                  <div
                      key={option}
                      className={`tone-option ${
                          selectedTopic.includes(option) ? "selected" : ""
                      }`}
                      onClick={() => toggleTopic(option)}
                  >
                    {option}
                  </div>
              ))}
            </div>
          </div>

          <div className="tone-container">
            <h3>Choose your tone of voice (up to 2 characteristics):</h3>
            <div className="tone-options">
              {toneOptions.map((option) => (
                  <div
                      key={option}
                      className={`tone-option ${
                          selectedTone.includes(option) ? "selected" : ""
                      }`}
                      onClick={() => toggleTone(option)}
                  >
                    {option}
                  </div>
              ))}
            </div>
          </div>
        </div>
        <div className="form-block">
          <h2>Advanced personalization</h2>
          <p>Providing this information allows us to design content that reflects your individuality, aspirations, and
            personal characteristics.</p>
          <div className="name-row">
            <div className="form-group">
              <label>Gender:</label>
              <div className="input-with-button">
                <select
                    name="gender"
                    value={brandVoice.gender || ''}
                    onChange={handleChange}
                    className="gender-select"
                >
                  <option value="">Choose gender preference</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="NotSpecified">Don't want to specify</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>Year of Birth:</label>
              <div className="input-with-button">
                <select
                    name="year_of_birth"
                    className="year-of-birth-select"
                    onChange={handleChange}
                    value={brandVoice.year_of_birth || ""}
                >
                  <option value="">Choose your year of birth</option>
                  {Array.from({length: 100}, (_, i) => new Date().getFullYear() - i).map(
                      (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="name-row">

          </div>
          <div className="name-row">
            <div className="form-group">
              <label>Describe your personality (up to 50 words):</label>
              <textarea
                  name="personality_description"
                  value={brandVoice.personality_description || ''}
                  onChange={handleWordLimitChange}
                  placeholder="Write your description"
              />
              <small>
                {brandVoice.personality_description ? brandVoice.personality_description.trim().split(/\s+/).length : 0}/50
                words
              </small>
            </div>

            <div className="form-group">
              <label>Share your sample LinkedIn post:</label>
              <textarea
                  name="tone_reflection"
                  value={brandVoice.tone_reflection || ''}
                  onChange={handleChange}
                  placeholder="Write your description"
              />
            </div>
          </div>
        </div>
        <div className="form-block">
          <h2>Comments Reflections</h2>
          <p>Review and update your reflections.</p>

          {brandVoice.comments_reflections.length > 0 ? (
              brandVoice.comments_reflections.map((comment, index) => (
                  <div className="input-group-row" key={index}>
                    <div className="input-group">
                      <label>{`Comment ${index + 1}`}</label>
                      <textarea
                          className="signup-input"
                          value={comment}
                          onChange={(e) => handleCommentChange(index, e.target.value)}
                          style={{height: "100px"}}
                      />
                    </div>
                    <button className="remove-button-comment" onClick={() => handleRemoveComment(index)}>
                      ❌ Remove
                    </button>
                  </div>
              ))
          ) : (
              <p>No comments available.</p>
          )}

          {/* Add Comment Button (Only show if less than 5 comments) */}
          {brandVoice.comments_reflections.length < 5 && (
              <button className="add-comment-button" onClick={handleAddComment}>
                ➕ Add Comment
              </button>
          )}
        </div>

        <button type="submit" className="persona-save-button">Save</button>
      </form>
  );
};

export default BrandVoice;
