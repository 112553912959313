import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/DirectMessage.css';
import { API_URL } from '../config';
import { useTemplate } from './TemplateContext';

const CreateDirectMessage = () => {
    const [templates, setTemplates] = useState([]);
    const [dmGoal, setDmGoal] = useState('');
    const [dmInstructions, setDmInstructions] = useState('');
    const [languageChoices, setLanguageChoices] = useState([]);
    const [scale, setScale] = useState(null); 
    const [error, setError] = useState('');
    const { selectedTemplate, setSelectedTemplate,Loading, setLoading,language, setLanguage } = useTemplate();
    

    // Default template details
    const defaultTemplate = {
        id: 'default',
        name: 'Template 1',
        content: `Hi {Name},\nI saw you're the {Role} at {Company} and really enjoyed your post on {Post_Topic}—great insights! We've been connected on LinkedIn for a while, but I'd love to get to know you in person. As much as I appreciate online networking, I prefer real conversations.\nWould you be available for a quick call or even a coffee sometime soon? We would like to learn more about each other's work and explore ways we might collaborate.\nThanks,\n{Person's First Name}`
    };

    useEffect(() => {
        const fetchData = async () => {
       
            setLoading('true');
            setError('');
            try {
                const [templatesResponse, languagesResponse] = await Promise.all([
                    axios.get(`${API_URL}/marketing_content/api/user-templates/`, {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
                    }),
                    axios.get(`${API_URL}/marketing_content/api/language-types/`, {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
                    })
                ]);

                setTemplates([defaultTemplate, ...templatesResponse.data]);
                setSelectedTemplate(defaultTemplate);  // Automatically set the first template
                setLanguageChoices(languagesResponse.data);
                if (languagesResponse.data.length > 0) {
                    setLanguage(languagesResponse.data[0].value);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data. Please try again later.');
                setTemplates([defaultTemplate]);
                setSelectedTemplate(defaultTemplate);
            } finally {
                setLoading(null);
            }
        };

        fetchData();
    }, []);

    const handleSelectTemplate = (templateId) => {
        const selected = templates.find(t => t.id.toString() === templateId.toString());
        setSelectedTemplate(selected);
    };

    const handleSubmit = async () => {
        setLoading('Loading');
         console.log('Loading:', Loading)
        const formData = new FormData();
        formData.append('goal', dmGoal);
        formData.append('instructions', dmInstructions);
        formData.append('templateContent', selectedTemplate.content); // Ensure this matches backend expectation
        formData.append('templateContent', selectedTemplate.content); // Ensure this matches backend expectation
        formData.append('language', language);
        
        console.log(selectedTemplate.content)
        console.log(typeof selectedTemplate.content);

       
        setError('');
    
        try {
            const response = await axios.post(`${API_URL}/marketing_content/api/create_direct_message/`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'multipart/form-data' // This line is actually not necessary as Axios sets it correctly based on FormData
                }
            });
            console.log('Response from server:', response.data, 'Type:', typeof response.data, 'Content:', response.data.content, typeof response.data.content);

            setSelectedTemplate({
                ...selectedTemplate,
                content: response.data.content,
                scale: response.data.scale, // Add the scale to the selectedTemplate
              });
          
              // If the scale is available from OpenAI-generated template, set it
              if (response.data.scale !== undefined) {
                setScale(response.data.scale);  // Set the scale from OpenAI-generated template
              }
        } catch (error) {
            console.error('Error sending data:', error);
            setError('Failed to send data. Please check your network and try again.');
        } finally {
            setLoading(null);
        }
    };
    

    return (
        <div className="dm-container">
            <div className="dm-header">
                <h1>DM</h1>
                <select className="language-select" value={language} onChange={(e) => setLanguage(e.target.value)}>
                    {languageChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>{choice.label}</option>
                    ))}
                </select>
            </div>
            <hr className="header-divider" />
            <div className="dm-content-sidebar">
                <div className="template-selection">
                    <label htmlFor="template">Choose a Template</label>
                    <select id="template" onChange={(e) => handleSelectTemplate(e.target.value)}>
                        {templates.map(template => (
                            <option key={template.id} value={template.id.toString()}>{template.name}</option>
                        ))}
                    </select>
                </div>
                <div className="dm-goal">
                    <label htmlFor="goal">DM goal</label>
                    <textarea id="goal" placeholder="Write your goal here..." value={dmGoal} onChange={(e) => setDmGoal(e.target.value)} />
                </div>
                <div className="dm-instructions">
                    <label htmlFor="instructions">DM instructions</label>
                    <textarea id="instructions" placeholder="Write your instructions here..." value={dmInstructions} onChange={(e) => setDmInstructions(e.target.value)} />
                </div>
                <button className="generate-button" onClick={handleSubmit} disabled={Loading}>Generate</button>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default CreateDirectMessage;