import React, {useRef, useState} from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // For an eye icon (install using `npm install react-icons`)
import axios from "axios";
import {Link} from "react-router-dom";
import google_image from "../assets/google.png";
import wizard_image from "../assets/wizard.png";
import login_content from "../assets/login_content.png";
import {API_URL} from "../config";
import "../styles/Login.css";
import {toast} from "react-hot-toast";
import {useToggle} from "../hooks/useToggle";

function Login() {
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const passwordRef = useRef();
    const [showPassword, toggleShowPassword] = useToggle(false); // State to toggle password visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastID = toast.loading("Loading...", {position: "top-right"});
        try {
            const response = await axios.post(`${API_URL}/users/login/`, credentials);
            if (response.data.access && response.data.refresh) {
                // Save tokens in localStorage
                localStorage.setItem("access_token", response.data.access);
                localStorage.setItem("refresh_token", response.data.refresh);

                // Save the access token in Chrome's storage
                if (chrome && chrome.storage) {
                    chrome.storage.local.set({ access_token: response.data.access }, () => {
                        console.log("Access token saved in Chrome storage");
                    });
                }
              
                toast.success(response.data.detail, {id: toastID}); // Pass success message to the toast
                toast("Redirecting to home page...", {position: "top-right"})
                // Redirect to login page (or any other route) after success
                setTimeout(() => {
                    window.location.href = "/protected";
                }, 3000); // Add a small delay to allow the toast to show
            } else {
                toast.error("Login failed: No tokens received.", {id: toastID});
            }
        } catch (error) {
            toast.error(error.response.data.detail, {id: toastID});
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `${API_URL}/google-login/`;
    };

    return (
        <div className="login-page">
            <div className="login-left">
                <div className="login-left-content">
                    <img
                        src={login_content}
                        alt="Brand Wizard Illustration"
                        className="login-left-image"
                    />
                    <h1>Brand Wizard</h1>
                    <p>
                        Your AI marketing magic: strategies & tools, crafted from your data
                        for your audience.
                    </p>
                </div>
            </div>
            <div className="login-right">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-header">
                        <img
                            src={wizard_image}
                            alt="Wizard Icon"
                            className="login-header-icon"
                        />
                        <h2>Welcome back</h2>
                        <p>Login below to get back your marketing superpowers</p>
                    </div>

                    <div className="login-input-group">
                        <input
                            type="text"
                            name="username"
                            className="login-input"
                            placeholder="Type your email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="login-input-group">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            ref={passwordRef}
                            className="login-input"
                            placeholder="Type your Password"
                            onChange={handleChange}
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-button"
                            title={showPassword ? "Hide password" : "Show password"}
                            onMouseDown={(e) => e.preventDefault()} // Avoid "stealing" focus from the input
                            onClick={toggleShowPassword}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                    <Link to="/forgot-password" className="forgot-password-link">
                        Forgot your password?
                    </Link>
                    <button className="login-button" type="submit">
                        Login
                    </button>
                    <div className="signup-link">
                        <p>
                            Don't have an account? <Link to="/signup">Sign up</Link>
                        </p>
                    </div>

                    <div className="divider">or</div>
                    <button
                        className="google-login-button"
                        type="button"
                        onClick={handleGoogleLogin}
                    >
                        <img src={google_image} alt="Google Logo" className="google-logo" />
                        Start with Google
                    </button>

                    <div className="contact-links">
                        <p>
                            Need help? Contact us at{" "}
                            <a href="mailto:team@brandwizard.ai">team@brandwizard.ai</a>
                        </p>
                        <p>
                            Read our <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
                            <Link to="/terms-of-service">Terms of Service</Link>.
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;