import React from "react";
import axios from "axios";
import { API_URL } from '../config';
import { toast } from "react-hot-toast";
import login_content from "../assets/login_content.png";
import wizard_image from "../assets/wizard.png";
import {Link} from "react-router-dom";

const ForgotPassword = () => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const email = data.get("email");
        // const res = await axios.post(`${API_URL}/users/forgot-password/`, {email: email});
        // if (res.data.success) {
        //     toast.success(res.data.message);
        // } else {
        //     toast.error(res.data.message);
        // }
        const myPromise = new Promise(async (resolve, reject) => {
            try {
                // Make the POST request
                const response = await axios.post(`${API_URL}/users/forgot-password/`, { email: email });

                // Resolve or reject based on success or failure from the server
                resolve(response.data); // Pass success message to the toast
            } catch (error) {
                // Handle cases where the request fails (e.g., 404, server error)
                if (error.response) {
                    // Server responded with an error status code
                    reject(new Error(error.response.data.detail || "An error occurred."));
                } else if (error.request) {
                    // Request made but no response received
                    reject(new Error("No response from the server. Please try again."));
                } else {
                    // Setup or unexpected error
                    reject(new Error("An unexpected error occurred. Please try again."));
                }
            }
        });
        toast.promise(myPromise,
            {loading: "Loading...", success: (data) => data.detail, error: (err) => err.toString()},
            {position: "top-right"})
    };

    return (
        <div className="login-page">
            <div className="login-left">
                <div className="login-left-content">
                    <Link to="/">
                    <img
                        src={login_content}
                        alt="Brand Wizard Illustration"
                        className="login-left-image"
                    />
                    </Link>
                    <h1>Brand Wizard</h1>
                    <p>
                        Your AI marketing magic: strategies & tools, crafted from your data
                        for your audience.
                    </p>
                </div>
            </div>
            <div className="login-right">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-header">
                        <Link to="/">
                        <img
                            src={wizard_image}
                            alt="Wizard Icon"
                            className="login-header-icon"
                        />
                        </Link>
                        <h2>Password Recovery</h2>
                        <p>Fill in your E-mail to receive a recovery link</p>
                    </div>

                    <div className="input-group">
                        <input
                            type="text"
                            name="email"
                            className="login-input"
                            placeholder="Type your email"
                            required
                        />
                    </div>

                    <button className="login-button" type="submit">
                        Reset Password
                    </button>
                    <div className="signup-link">
                        <p>
                            Go back to the <Link to="/login">Login</Link> page
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;