import React from 'react';
import classNames from 'classnames';

const SidebarItem = ({title, component, setComponent, selectedComponent, ImgComp, ImgAlt}) => {
  return (
    <li onClick={() => setComponent(component)} className={classNames({active: selectedComponent===component})}>
        <ImgComp alt={ImgAlt} className="sidebar-icon"/>
        <span>{title}</span>
    </li>
  );
};

export default SidebarItem;