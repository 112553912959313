import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../config";
import "../styles/Favorites.css";
import "../styles/LinkedInProspects.css";
import ImageLoadingSpinner from "./ImageLoadingSpinner";

const LinkedInProspects = ({ csrfToken }) => {
  const [prospects, setProspects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newUsername, setNewUsername] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const fetchProspects = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      setError("Authorization error.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/prospects-objects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setProspects(data.linkedin_prospects);
        setError(null);
      } else {
        setError("Failed to fetch prospects.");
      }
    } catch (error) {
      console.error("Error fetching prospects:", error);
      setError("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  }, [csrfToken]);

  useEffect(() => {
    fetchProspects();
  }, [fetchProspects]);

  const handleAddProspect = async () => {
    if (newUsername.trim() === "") return;

    const token = localStorage.getItem("access_token");
    if (!token || !csrfToken) {
      console.error("Missing required tokens.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/create-linkedin-prospect/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ username: newUsername.trim() }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Prospect created:", data);
        setNewUsername(""); // Clear input after success
        fetchProspects(); // Refresh table
      } else {
        setError("Failed to add prospect.");
      }
    } catch (error) {
      console.error("Error adding prospect:", error);
      setError("An unexpected error occurred.");
    }
  };

  const filteredProspects = prospects.filter((prospect) =>
    `${prospect.firstName} ${prospect.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDelete = async (prospectId) => {
  const token = localStorage.getItem("access_token");

  if (!token || !csrfToken) {
    console.error("Missing required tokens: Authorization or CSRF.");
    return;
  }

  if (!window.confirm("Are you sure you want to delete this prospect?")) {
    return;
  }

  try {
    const response = await fetch(`${API_URL}/marketing_content/api/prospects-objects/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-CSRFToken": csrfToken,
      },
      credentials: "include",
      body: JSON.stringify({ prospect_id: prospectId }),
    });

    if (response.ok) {
      setProspects((prevProspects) =>
        prevProspects.filter((prospect) => prospect.id !== prospectId)
      );
    } else {
      console.error("Failed to delete prospect:", response.statusText);
    }
  } catch (error) {
    console.error("Error deleting prospect:", error);
  }
};


  return (
    <div className="dashboard-container">
      {/* Add Prospect Section */}
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter LinkedIn username"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          className="prospect-input"
        />
        <button className="add-prospect-button" onClick={handleAddProspect}>
          Add New Prospect
        </button>
      </div>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search prospects by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />

      {isLoading ? (
        <ImageLoadingSpinner message="Loading prospects" />
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
          <table className="documents-table">
            <thead>
            <tr>
              <th>Picture</th>
              <th>Prospect Name</th>
              <th>Title</th>
              <th>Company</th>
              <th>Country</th>
              <th>Headline</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {filteredProspects.map((prospect) => (
                <tr key={prospect.id}>
                  <td>
                    {prospect.profilePicture ? (
                        <img src={prospect.profilePicture} alt={prospect.firstName} className="prospect-image"/>
                    ) : (
                        <div className="prospect-placeholder"></div> // Placeholder if no image
                    )}
                  </td>
                  <td className="table-text">{prospect.firstName} {prospect.lastName}</td>
                  <td className="table-text">{prospect.title}</td>
                  <td className="table-text">{prospect.company}</td>
                  <td className="table-text">{prospect.country}</td>
                  <td className="table-text">{prospect.headline}</td>
                  <td>
                    <button
                        className="delete-button"
                        onClick={() => handleDelete(prospect.id)}
                    >
                      ❌ Delete
                    </button>
                  </td>
                </tr>
            ))}
            </tbody>

          </table>
      )}
    </div>
  );
};

export default LinkedInProspects;