import React, {useState} from 'react';
import SidebarTitle from "./SidebarTitle";
import {useToggle} from "../hooks/useToggle";
import '../styles/Sidebar.css'; // Add your CSS file
import logo from '../assets/logo.jpg'; // Adjust the path to your logo
import hamburgerIcon from '../assets/hamburger.png'; // Add your hamburger icon image
import closeIcon from '../assets/close.png'; // Add your close (X) icon image
import contentCreationIcon from '../assets/content_creation.png';
import statisticsIcon from '../assets/statistics.png';
import chatIcon from '../assets/chat.png';
import adsIcon from '../assets/ads.png';
import socialIcon from '../assets/social.png';
import prIcon from '../assets/pr.png';
import emailIcon from '../assets/email.png'
import ecommerceIcon from '../assets/ecommerce.png'
import generalIcon from '../assets/general.png'
import refineIcon from '../assets/refine.png'
import contentBoosterIcon from '../assets/content_booster.png'
import translateIcon from '../assets/translation.png'
import GoogleAnalyticsImage from '../assets/google-analytics.png'
import GoogleSearchImage from '../assets/google_search.png'
import CompetitorsImage from '../assets/competitor-analysis.png'
import TrendsImage from '../assets/google_trends.png'
import ChatAi from '../assets/chat_ai.png'
import SeoImage from '../assets/seo.png'
import KeyWordImage from '../assets/keyword.png'
import CompetitorsMain from "../assets/competitors_main.png";
import DomainImage from "../assets/domain.png";
import BrandVoice from "../assets/brand_voice.png"
import BrandStrategy from "../assets/brand_strategy.png";
import TargetAudience from "../assets/target_audience.png";
import BrandProducts from "../assets/brand_products.png"
import BrandKnowledge from "../assets/brand_knowledge.png";
import TopPerforming from "../assets/top_performing.png";
import BrandBook from "../assets/brand_book.png";
// Correct import for SVG as a React component
import {ReactComponent as ComingSoon} from '../assets/coming_soon_old.svg'
import {ReactComponent as LinkedIn} from '../assets/Linkedin.svg'
import {ReactComponent as HomeImage} from "../assets/home.svg";
import {ReactComponent as Brainstorm} from '../assets/Brainstorm.svg';
import {ReactComponent as Hook} from '../assets/Hook.svg';
import {ReactComponent as Post} from '../assets/Post.svg';
import {ReactComponent as DM} from '../assets/DM.svg';
import {ReactComponent as CreateImageIcon} from '../assets/image_create.svg'
import {ReactComponent as CreateImageAiIcon} from '../assets/image_ai.svg'
import {ReactComponent as StudioImage} from "../assets/studio.svg";
import {ReactComponent as DashboardImage} from "../assets/dashboard.svg";
import {ReactComponent as BookMarkImage} from "../assets/bookmark.svg"
import {ReactComponent as RadarImage} from "../assets/radar.svg"
import {ReactComponent as ProspectsImage} from "../assets/Prospects.svg";
import {ReactComponent as AiPersonalization} from "../assets/ai_personalization.svg"


const Sidebar = ({ setComponent, selectedComponent }) => {
    const [isCollapsed, toggleIsCollapsed] = useToggle(false); // Sidebar collapsed state
    // A hook on the toggle hook to always open the menu on click if collapsed
    const useToggleDropdown = (initialValue) => {
        const [value, toggleValue] = useToggle(initialValue);
        const toggle = () => {
            if (isCollapsed) toggleIsCollapsed();
            toggleValue()
        }
        return [value, toggle];
    }

    const [isDropdownOpen, toggleIsDropdownOpen] = useToggleDropdown(false); // State to manage "Content Creation" dropdown
    const [isAnalyticsDropdownOpen, toggleIsAnalyticsDropdownOpen] = useToggleDropdown(false);
    const [isChatDropdownOpen, toggleIsChatDropdownOpen] = useToggleDropdown(false);
    const [isSEODropdownOpen, toggleIsSEODropdownOpen] = useToggleDropdown(false);
    const [isBrandDropdownOpen, toggleIsBrandDropdownOpen] = useToggleDropdown(false);
    const [isCompetitorsDropdownOpen, toggleIsCompetitorsDropdownOpen] = useToggleDropdown(false);
    const [isStudioDropdownOpen, toggleIsStudioDropdownOpen] = useToggleDropdown(false);
    const [isStrategyDropdownOpen, toggleIsStrategyDropdownOpen] = useToggleDropdown(false);
    const [isDashboardDropdownOpen, toggleIsDashboardDropdownOpen] = useToggleDropdown(false);
    const [isFavoritesDropdownOpen, toggleIsFavoritesDropdownOpen] = useToggleDropdown(false);
    const [isRadarDropdownOpen, toggleIsRadarDropdownOpen] = useToggleDropdown(true); // Radar is the default view on page load, so have its menu open


    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="sidebar-logo-container">
                <img src={logo} alt="Logo" className="logo" onClick={toggleIsCollapsed}/>
                {!isCollapsed && <span className="product-name">BrandWizard</span>}
            </div>
            <div className="sidebar-content">
                <SidebarTitle title="Posts Radar" isCollapsed={isCollapsed} isDropdownOpen={isRadarDropdownOpen} ImgComp={RadarImage}
                              clickFunc={toggleIsRadarDropdownOpen} ImgAlt="Radar" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Radar', ImgComp: RadarImage, ImgAlt: 'Radar', component: "Radar"},
                                  {title: 'Prospects posts', ImgComp: ProspectsImage, ImgAlt: 'RadarProspects', component: "RadarProspects"}]}/>

                <SidebarTitle title="Dashboard" isCollapsed={isCollapsed} isDropdownOpen={isDashboardDropdownOpen} ImgComp={HomeImage}
                              clickFunc={toggleIsDashboardDropdownOpen} ImgAlt="Dashboard" isStroke={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Trends', ImgComp: DashboardImage, ImgAlt: 'Dashboard', component: "Dashboard"}]}/>

                <SidebarTitle title="LinkedIn" isCollapsed={isCollapsed} isDropdownOpen={isDropdownOpen} ImgComp={LinkedIn}
                              clickFunc={toggleIsDropdownOpen} ImgAlt="Content Creation Icon"
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Brainstorm', ImgComp: Brainstorm, ImgAlt: 'Brainstorm Icon', component: "CreateSocial"},
                                  {title: 'Hooks', ImgComp: Hook, ImgAlt: 'PR Icon', component: "CreatePR"},
                                  {title: 'Post', ImgComp: Post, ImgAlt: 'Ads Icon', component: "CreateAd"},
                                  {title: 'DM', ImgComp: DM, ImgAlt: 'Email Icon', component: "CreateEmail"},
                                  { title: 'DM template', ImgComp: AiPersonalization, ImgAlt: 'DM Icon', component: "CreateDM" },
                              ]}/>

                <SidebarTitle title="Favorites" isCollapsed={isCollapsed} isDropdownOpen={isFavoritesDropdownOpen} ImgComp={BookMarkImage}
                              clickFunc={toggleIsFavoritesDropdownOpen} ImgAlt="BookMark" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Favorites', ImgComp: BookMarkImage, ImgAlt: 'Favorites', component: "Favorites"},
                                  {title: 'Prospects', ImgComp: ProspectsImage, ImgAlt: 'Prospects', component: "Prospects"}]}/>

                {/*<SidebarTitle title="Data & Analytics" isCollapsed={isCollapsed} isDropdownOpen={isAnalyticsDropdownOpen} ImgComp={statisticsIcon}
                              clickFunc={toggleIsAnalyticsDropdownOpen} ImgAlt="Statistics Icon" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Google Analytics', ImgComp: GoogleAnalyticsImage, ImgAlt: 'Google Analytics', component: "CreateAnalytics"},
                                {title: 'Google Search', ImgComp: GoogleSearchImage, ImgAlt: 'Google Search', component: "CreateSearch"},
                                {title: 'Google Trends', ImgComp: TrendsImage, ImgAlt: 'Google Trends', component: "CreateTrends"}]}/>*/}

                {/*<SidebarTitle title="AI Chat" isCollapsed={isCollapsed} isDropdownOpen={isChatDropdownOpen} ImgComp={chatIcon}
                              clickFunc={toggleIsChatDropdownOpen} ImgAlt="AI Chat Icon" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'AI Chat', ImgComp: ChatAi, ImgAlt: 'Chat AI', component: "Chat"}]}/>*/}

                {/*<SidebarTitle title="SEO" isCollapsed={isCollapsed} isDropdownOpen={isSEODropdownOpen} ImgComp={SeoImage}
                              clickFunc={toggleIsSEODropdownOpen} ImgAlt="" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Keywords Ideas', ImgComp: KeyWordImage, ImgAlt: 'Keywords Ideas', component: "KeywordsIdeas"},
                                {title: 'Keywords Suggestions', ImgComp: KeyWordImage, ImgAlt: 'Keywords Suggestions', component: "KeywordsSuggestions"},
                                {title: 'Competitors Keywords', ImgComp: KeyWordImage, ImgAlt: 'Competitors Keywords', component: "CompetitorsKeywords"},
                                {title: 'Blog Wizard - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Blog Wizard', component: ""},
                                {title: 'Website SEO - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Website SEO', component: ""},
                                {title: 'Search Console - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Search Console', component: ""}]}/>*/}

                {/*<SidebarTitle title="Competition" isCollapsed={isCollapsed} isDropdownOpen={isCompetitorsDropdownOpen} ImgComp={CompetitorsMain}
                              clickFunc={toggleCompetitorsDropdown} ImgAlt="Competitors main" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Competitors Analysis', ImgComp: CompetitorsImage, ImgAlt: 'Competitors Analysis', component: "CompetitorsAnalysis"},
                                {title: 'Competitors Keywords', ImgComp: KeyWordImage, ImgAlt: 'Competitors Keywords', component: "CompetitorsKeywords"},
                                {title: 'Competitors Domains', ImgComp: DomainImage, ImgAlt: 'Competitors Domain', component: "CompetitorsDomain"}]}/>*/}

                <SidebarTitle title="Creative studio" isCollapsed={isCollapsed} isDropdownOpen={isStudioDropdownOpen} ImgComp={StudioImage}
                              clickFunc={toggleIsStudioDropdownOpen} ImgAlt="Competitors main" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Stock Images', ImgComp: CreateImageIcon, ImgAlt: 'Create img Icon', component: "CreateImage"},
                                  {title: 'Create AI image', ImgComp: CreateImageAiIcon, ImgAlt: 'Create img AI Icon', component: "CreateImageAI"}]}/>

                <SidebarTitle title="AI personalization" isCollapsed={isCollapsed} isDropdownOpen={isBrandDropdownOpen} ImgComp={AiPersonalization}
                              clickFunc={toggleIsBrandDropdownOpen} ImgAlt="Coming soon" isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Personalization', ImgComp: AiPersonalization, ImgAlt: 'Brand identity', component: "BrandVoice"},
                                  /*{title: 'Brand strategy', ImgComp: BrandStrategy, ImgAlt: 'Brand strategy', component: "BrandStrategy"},
                                  {title: 'Target audience', ImgComp: TargetAudience, ImgAlt: 'Target audience', component: "TargetAudience"},
                                  {title: 'Brand products', ImgComp: BrandProducts, ImgAlt: 'Brand products', component: "ProductList"},
                                  {title: 'Brand Knowledge', ImgComp: BrandKnowledge, ImgAlt: 'Brand Knowledge', component: "BrandKnowledge"},
                                  {title: 'Top Performing', ImgComp: TopPerforming, ImgAlt: 'Top Performing', component: "TopPerforming"}*/]}/>

                {/*<SidebarTitle title={"Strategy & Planning - (Coming soon)"} isCollapsed={isCollapsed} isDropdownOpen={isStrategyDropdownOpen} ImgComp={ComingSoon}
                              clickFunc={toggleIsStrategyDropdownOpen} ImgAlt={"Coming soon"} isInverted={true}
                              setComponent={setComponent} selectedComponent={selectedComponent}
                              items={[{title: 'Marketing strategy - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Coming soon', component: "a"},
                                {title: 'SWOT Analysis - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Coming soon', component: selectedComponent},
                                {title: 'Gap Analysis - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Coming soon', component: selectedComponent},
                                {title: 'Content Plan - (Coming soon)', ImgComp: ComingSoon, ImgAlt: 'Coming soon', component: selectedComponent}]}/>*/}
            </div>
        </div>
    );
};

export default Sidebar;