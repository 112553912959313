import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../config";
import "../styles/Radar.css";
import ImageLoadingSpinner from "./ImageLoadingSpinner";

const RadarProspects = ({ csrfToken, onFavoriteSelect }) => {
  const [radarData, setRadarData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState({});

  const fetchRadarPosts = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem("access_token");
    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      setError("Authorization failed. Retrying...");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/radar-prospects-objects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setRadarData(data.slice(0, 6));
      } else {
        setError("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [csrfToken]);

  useEffect(() => {
    fetchRadarPosts();
  }, [fetchRadarPosts, csrfToken]);

  const fetchProspectPosts = async (username) => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem("access_token");
    if (!token || !csrfToken) {
      console.error("Missing authentication tokens.");
      setError("Authorization failed. Retrying...");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/radar-prospects-objects/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        const data = await response.json();
        setRadarData(data);
      } else {
        setError("Failed to fetch prospect posts. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching prospect posts:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSavePost = async (itemText, index) => {
    const token = localStorage.getItem("access_token");
    if (!token || !csrfToken) {
      console.error("Missing authentication tokens.");
      return;
    }

    setSaving((prev) => ({ ...prev, [index]: true }));

    const formData = new URLSearchParams();
    formData.append("content", itemText);

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/create-linkedin-post/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: "include",
        body: formData.toString(),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Failed to save post:", response.statusText, errorResponse);
      }
    } catch (error) {
      console.error("Error saving post:", error);
    } finally {
      setSaving((prev) => ({ ...prev, [index]: false }));
    }
  };

const renderRadarBoxes = () => {
  return radarData.map((item, index) => {
    const profileImage = item.author_image || "";
    const isLargeBox = item.text.length > 300;

    return (
      <div key={index} className="radar-row"> {/* Each post in a separate row */}
        <div className={`radar-box ${isLargeBox ? "radar-box-large" : ""}`}>
          <div className="radar-box-header">
            {profileImage ? (
              <img src={profileImage} alt={item.author} className="author-image" />
            ) : (
              <div className="author-image-placeholder"></div>
            )}
            <span className="author-name">{item.author || "Unknown Author"}</span>
          </div>

          <pre className="radar-box-content">{item.text}</pre>

          {/* Display Video First, Then Image */}
          {item.video ? (
            <div className="post-media-container">
              <video className="post-media" controls>
                <source src={item.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : item.image ? (
            <div className="post-media-container">
              <img className="post-media" src={item.image} alt="Post visual" />
            </div>
          ) : null}

          {/* Buttons */}
          <div className="radar-buttons">
            <button className="fetch-prospect-posts-button" onClick={() => fetchProspectPosts(item.username)}>
              Get All Recent Prospect's Posts
            </button>

            <button className="go-to-post-button" onClick={() => window.open(item.url, "_blank", "noopener noreferrer")}>
              Go to the Post
            </button>

            <button className="add-to-favorites-button" onClick={() => onFavoriteSelect(item.text)}>
              Generate a post
            </button>

            <button
              className="save-post-button"
              onClick={() => handleSavePost(item.text, index)}
              disabled={saving[index]}
            >
              {saving[index] ? "Saving..." : "Save"}
            </button>
          </div>

          {/* Footer with social activity */}
          <div className="radar-box-footer">
            <span>👍 {item.social_activity.likeCount}</span>
            <span>💬 {item.social_activity.numComments}</span>
            <span>❤️ {item.social_activity.empathyCount}</span>
            <span>🙌 {item.social_activity.praiseCount}</span>
          </div>
        </div>
      </div>
    );
  });
};


  return (
      <div className="radar-container">
        <div className="top-section">
          {isLoading ? <ImageLoadingSpinner message="Loading prospects posts" /> :
              <button
                  className="refresh-posts-button"
                  onClick={fetchRadarPosts}
                  disabled={isLoading}
              >
                Get Newest Prospects Posts
              </button>
          }
        </div>

        {error ? (
            <div className="error-message">{error}</div>
        ) : (
            <div className="radar-grid">{renderRadarBoxes()}</div>
        )}
      </div>
  );
};

export default RadarProspects;