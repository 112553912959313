import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../config";
import "../styles/Radar.css";
import ImageLoadingSpinner from "./ImageLoadingSpinner";

const Radar = ({ csrfToken, onFavoriteSelect }) => {
  const [radarData, setRadarData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState({}); // Track saving state for each item

  const fetchRadarPosts = useCallback(async () => {
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      setError("Authorization failed. Retrying...");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/radar-posts-objects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setRadarData(data.slice(0, 6)); // Display only the first 6 items
        setError(null);
      } else {
        console.error("Failed to fetch data:", response.statusText);
        setError("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [csrfToken]);

  useEffect(() => {
    fetchRadarPosts();
  }, [fetchRadarPosts, csrfToken]);

  // Function to handle "Save" button click
  const handleSavePost = async (itemText, index) => {
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing authentication tokens.");
      return;
    }

    setSaving((prev) => ({ ...prev, [index]: true })); // Set loading state for this item

    const formData = new URLSearchParams();
    formData.append("content", itemText); // ✅ Properly encode form data

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/create-linkedin-post/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/x-www-form-urlencoded",  // ✅ Correct form encoding
        },
        credentials: "include",
        body: formData.toString(),  // ✅ Send as form data
      });

      if (response.ok) {
        console.log("Post saved successfully!");
      } else {
        const errorResponse = await response.json();
        console.error("Failed to save post:", response.statusText, errorResponse);
      }
    } catch (error) {
      console.error("Error saving post:", error);
    } finally {
      setSaving((prev) => ({ ...prev, [index]: false })); // Remove loading state
    }
  };


  const renderRadarBoxes = () => {
    const rows = [];
    let currentRow = [];

    radarData.forEach((item, index) => {
      const isLargeBox = item.text.length > 300;
      const profileImage = item.author_image || "";

      const radarBox = (
          <div key={index} className={`radar-box ${isLargeBox ? "radar-box-large" : ""}`}>
            <div className="radar-box-header">
              {profileImage ? (
                  <img src={profileImage} alt={item.author} className="author-image" />
              ) : (
                  <div className="author-image-placeholder"></div>
              )}
              <span className="author-name">{item.author || "Unknown Author"}</span>

              {/* Buttons Container */}
              <div className="radar-buttons">
                {/* Go to the Post Button */}
                <button
                    className="go-to-post-button"
                    onClick={() => window.open(item.url, "_blank", "noopener noreferrer")}
                >
                  Go to the Post
                </button>

                {/* Generate a Post Button */}
                <button
                    className="add-to-favorites-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onFavoriteSelect(item.text);
                    }}
                >
                  Generate a post
                </button>

                {/* Save Button */}
                <button
                    className="save-post-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSavePost(item.text, index);
                    }}
                    disabled={saving[index]} // Disable button while saving
                >
                  {saving[index] ? "Saving..." : "Save"}
                </button>
              </div>
            </div>

            <pre className="radar-box-content">{item.text}</pre>

            <div className="radar-box-footer">
              <span>👍 {item.social_activity.likeCount}</span>
              <span>💬 {item.social_activity.numComments}</span>
              <span>👏 {item.social_activity.appreciationCount}</span>
              <span>❤️ {item.social_activity.empathyCount}</span>
              <span>🌟 {item.social_activity.InterestCount}</span>
              <span>🙌 {item.social_activity.praiseCount}</span>
              <span>😂 {item.social_activity.funnyCount}</span>
            </div>
          </div>
      );

      if (isLargeBox) {
        if (currentRow.length > 0) {
          rows.push(
              <div key={`row-${rows.length}`} className="radar-row">
                {currentRow}
              </div>
          );
          currentRow = [];
        }
        rows.push(
            <div key={index} className="radar-row">
              {radarBox}
            </div>
        );
      } else {
        currentRow.push(radarBox);
        if (currentRow.length === 2) {
          rows.push(
              <div key={`row-${rows.length}`} className="radar-row">
                {currentRow}
              </div>
          );
          currentRow = [];
        }
      }
    });

    if (currentRow.length > 0) {
      rows.push(
          <div key={`row-${rows.length}`} className="radar-row">
            {currentRow}
          </div>
      );
    }

    return rows;
  };

  return (
      <div className="radar-container">
        {isLoading ? (
            <ImageLoadingSpinner message="Loading Radar Posts" />
        ) : error ? (
            <div className="error-message">{error}</div>
        ) : (
            <div className="radar-grid">{renderRadarBoxes()}</div>
        )}
      </div>
  );
};

export default Radar;