import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/directMessageComponent.css';
import linkedinIcon from '../assets/LinkedIn 4.png'
import { ReactComponent as HeaderIcon } from '../assets/headerIcon.svg';
import { ReactComponent as PencilIcon } from '../assets/pencilIcon.svg';
import { ReactComponent as RequestIconEdit } from '../assets/requestIconEdit.svg';
import { ReactComponent as CopyTemplate } from '../assets/CopyTemplate.svg';
import { ReactComponent as EditTemplate } from '../assets/EditTemplate.svg';
import { ReactComponent as Vector } from '../assets/Vector.svg';
import { useTemplate } from './TemplateContext'; // Ensure this is the correct path
import ImageLoadingSpinner from './ImageLoadingSpinner'; 
import { Doughnut } from 'react-chartjs-2';
const handleCopy = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => alert(`Copied to clipboard!`))
    .catch(err => console.error('Failed to copy text: ', err));
};

function isRtl(text) {
  // Regular expression to detect RTL characters
  const rtlChars = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F]/;
  return rtlChars.test(text);
}

const DynamicParameters = ({ insertParameter }) => {
  const parameters = [
    { key: '{Name}', desc: 'First name of the post writer.' },
    { key: '{Company}', desc: 'Company name of the post writer.' },
    { key: '{Role}', desc: 'Role of the post writer without the company name.' },
    { key: '{Post_Topic}', desc: 'Main post topic.' },
    { key: '{Post_Insight}', desc: 'Post insight that is relevant to you.' }
  ];

  return (
    <div className="dynamic-parameters">
      <h2>Dynamic Parameters</h2>
      <p>For personalization, parameters will be populated based on recipient & post</p>
      <hr className="header-divider" />
      <ul>
        {parameters.map(param => (
          <li key={param.key} onClick={() => insertParameter(param.key)}>
            <span className="parameter">{param.key}</span> - <span className="description">{param.desc}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
// renderChart function here
const renderChart = (scale) => {
  const data = {
    datasets: [
      {
        data: [scale, 100 - scale],
        backgroundColor: ['#BC43FF', '#f8eaff'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    cutout: '70%',
    responsive: true,
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <div className="chart-wrapper">
      <Doughnut data={data} options={options} width={90} height={90} />
    </div>
  );
};


const DirectMessageComponent = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    linkedInUrl: '',
    profilePicture: ''
  });
  
  const { selectedTemplate,Loading } = useTemplate();
  const [templateName, setTemplateName] = useState('');
  const [editableText, setEditableText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);
  const { scale } = useTemplate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${API_URL}/users/profile/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setProfileData({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          linkedInUrl: response.data.profile_url,
          profilePicture: response.data.profile_picture_url
        });
      } catch (error) {
        console.error('Error fetching profile data:', error.response);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      setEditableText(selectedTemplate.content);
      setTemplateName(selectedTemplate.name);
      console.log(selectedTemplate.content, "selectedtemplate")
      // setLoading('loading')
    }
  }, [selectedTemplate]);

  const insertParameter = (text) => {
    const textarea = textareaRef.current;
    if (textarea && document.activeElement === textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const textBefore = editableText.substring(0, start);
      const textAfter = editableText.substring(end, editableText.length);
      const newText = textBefore + text + textAfter;
      setEditableText(newText);
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + text.length;
      }, 0);
    } else {
      const newText = editableText + text;
      setEditableText(newText);
    }
  };

  const handleSaveTemplate = async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.post(`${API_URL}/marketing_content/api/create-dm-template/`, {
        name: templateName,
        content: editableText,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      alert('Template saved successfully!');
      setTemplateName(''); // Optionally reset the template name after saving
    } catch (error) {
      console.error('Error saving the template:', error.response);
      alert('Failed to save template');
    }
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  // console.log('Loading:', Loading)
  return (
    <div className="dm-page-container">
      {Loading ? <ImageLoadingSpinner message="Generating your message..." /> : (
        <>
          <div className="dm-header-section">
            <h1>DM Template Creation & Management</h1>
          </div>
          <div className="dm-template-section">
            <div className="dm-template-section-in">
              <HeaderIcon />
              <h1>Build Your DM Template</h1>
            </div>
            <p>Here you can create the DM template, edit & refine. Then, using our chrome extension on LinkedIn, you can generate a DM by clicking on...
              <Vector className="vector-icon"/>
            </p>
          </div>
          <div className="dm-form">
            <PencilIcon />
            <input
              type="text"
              placeholder="Give a name to this DM template"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
            <button className="save-dm" onClick={handleSaveTemplate}>Save DM</button>
          </div>
          <div className="grid-section">
              <div className="left-section">
              <div className="dm-content">
                <div className="dm-template-preview">
                  
                  <div className="profile-header">
                    <div className="profile-info">
                      <span>{profileData.firstName} {profileData.lastName}</span>
                    </div>
                    <div className="profile-info">
                      <CopyTemplate className="icon-copy" onClick={() => handleCopy(editableText)} />
                      <EditTemplate className="icon-edit" onClick={toggleEditing} />
                    </div>
                  </div>
                  {isEditing ? (
                    <textarea
                      ref={textareaRef}
                      value={editableText}
                      onChange={(e) => setEditableText(e.target.value)}
                      onBlur={() => setIsEditing(false)}
                      className="editable-textarea"
                      style={{
                        direction: isRtl(editableText) ? 'rtl' : 'ltr',
                        textAlign: isRtl(editableText) ? 'right' : 'left'
                      }}
                    />
                  ) : (
                    <div
                      className="dm-body"
                      style={{
                        direction: isRtl(editableText) ? 'rtl' : 'ltr',
                        textAlign: isRtl(editableText) ? 'right' : 'left'
                      }}
                    >
                      {editableText.split('\n').map((item, key) => (
                        <span key={key}>{item}<br/></span>
                      ))}
                    </div>
                  )}
                  </div>
                </div>
              </div>
              <div className="right-section">
                
                  <div className="progress-score-container">
                    <div className="chart-container">
                    <img src={linkedinIcon} alt="LinkedIn" className="chart-icon"/>
                  {scale !== undefined && renderChart(scale)} 
                  <p className="scale-value"> {scale}</p>                                        
                       </div>                                                                                     
                    <div className="score-buttons">
                  <button className="progress-button-regenerate">Re-generate DM</button>
                  <button className="progress-button-boost">Magic Boost</button>
                  <button className="progress-button-refine">Refine</button>
                  </div>
                  </div>

                  <DynamicParameters insertParameter={insertParameter} />
                </div>
            
            </div>
          <div className="edit-request-section">
            <input
              type="text"
              placeholder="Write here a request to edit the DM in a specific way"
              className="edit-request-input"
            />
            <button className="edit-request-btn">
              <div className="button-content">
              <div className="top-content">
                <RequestIconEdit />
                <span>Request Edit</span>
                </div>
                <span className="coming-soon-text">Coming soon</span>
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DirectMessageComponent;