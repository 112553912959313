import React from 'react';
import GenerateButton from "./GenerateButton";

const LinkedInForm = ({handleSubmit, handleChange, formData, languageTypeChoices, loading=false, children}) => {
  return (
      <form onSubmit={handleSubmit} style={{gap: "15px"}}>
            <div className="form-header">
                <span className="form-header-title">LinkedIn</span>
                <select
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    className="form-header-language"
                >
                    <option value="" disabled>English (American)</option>
                    {languageTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            {children}

            <GenerateButton disabled={loading} />
        </form>
  );
};

export default LinkedInForm;