import React from 'react';
import '../styles/ImageLoadingSpinner.css';
import logo from "../assets/logo.jpg"; // Create a CSS file for the spinner styles

const ImageLoadingSpinner = ( {message} ) => {
  return (
      <div className="loading-spinner">
          <div className="logo-container">
              <img src={logo} alt="Loading logo" className="loading-logo" />
              <div className="loading-ring"></div>
          </div>
          <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
          </div>
          <p>{message}...</p>
      </div>
  );
};

export default ImageLoadingSpinner;
