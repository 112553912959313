

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ProtectedApp from './ProtectedApp';
import GoogleCallback from './components/GoogleCallback';  // Component for Google login callback
import { checkJWTAuthentication, login } from './auth';  // JWT authentication check and login
import axios from 'axios';
import { API_URL } from './config';  // Assuming you have your API URL here
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import {Toaster} from "react-hot-toast";
import { TemplateProvider } from './components/TemplateContext';  // Ensure this import is correct based on your file structure

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state to handle asynchronous checks

  useEffect(() => {
    const checkAuthStatus = async () => {
      const currentUrl = window.location.pathname;

      // **Skip JWT check if it's Google callback**
      if (currentUrl === '/google/callback') {
        console.log("In Google callback, authentication will be handled by the GoogleCallback component.");
        return; // Skip JWT check since GoogleCallback will handle this
      }

      console.log("Checking JWT authentication...");
      const isAuth = await checkJWTAuthentication();  // JWT check for regular login
      console.log(`JWT Authentication result: ${isAuth}`);

      if (!isAuth) {
        console.log("No JWT token found, checking Google login tokens...");
        try {
          // Fallback: Try to fetch Google login tokens if JWT isn't present
          const response = await axios.get(`${API_URL}/check_google_login/`, { withCredentials: true });
          if (response.data.access_token && response.data.refresh_token) {
            console.log("Google login tokens found, logging in...");
            login(response.data.access_token, response.data.refresh_token);
            setAuthenticated(true);
          } else {
            console.log("No Google login tokens found.");
            setAuthenticated(false);
          }
        } catch (error) {
          console.error("Error checking Google login tokens:", error);
          setAuthenticated(false);
        }
      } else {
        setAuthenticated(true);
      }

      setLoading(false);  // Stop loading after the authentication check
    };

    checkAuthStatus();
  }, []);

  if (loading) {
    return <p>Loading...</p>;  // Render a loading indicator while checking authentication
  }

  return (
    <TemplateProvider> {/* Wrap the Router and its components in TemplateProvider */}
        <Toaster toastOptions={{ style: {background: '#7C76F5', color: '#FFF'} }} />
        <Router>
          <Routes>
            {/* Route for regular login */}
            <Route path="/login" element={<Login />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />

            {/* Route for user signup */}
            <Route path="/signup" element={<Signup />} />

            {/* Route for Google OAuth callback */}
            <Route
                path="/google/callback"
                element={<GoogleCallback setAuthenticated={setAuthenticated} />} // GoogleCallback handles the Google login flow
            />

            {/* Route for protected pages after authentication */}
            <Route
                path="/*"
                element={
                  authenticated ? (
                      <ProtectedApp />
                  ) : (
                      <Navigate to="/login" />
                  )}
            />
          </Routes>
        </Router>
    </TemplateProvider>
  );
}

export default App;

