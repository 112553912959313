import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast'
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { API_URL } from './config';
import './App.css';
// Import Components
import Sidebar from './components/Sidebar';
import CreateAd from './components/CreateAd';
import CreateSocial from './components/CreateSocial';
import CreateEmail from './components/CreateEmail';
import CreatePR from './components/CreatePR';
import CreateGeneral from './components/CreateGeneral';
import CreateEcommerce from './components/CreateEcommerce';
import CreateRefine from './components/CreateRefine';
import CreateBoost from './components/CreateBoost';
import CreateTranslate from './components/CreateTranslate';
import CreateImage from './components/CreateImage';
import CreateImageAI from './components/CreateImageAI';
import CreateAnalytics from './components/CreateAnalytics';
import CountryAnalyticsChart from './components/CountryAnalyticsChart';
import CityAnalyticsChart from './components/CityAnalyticsChart';
import LandingPageSessionsChart from './components/LandingPageSessionsChart';
import LandingPageBounceChart from './components/LandingPageBounceChart';
import EngagedSessionsChart from './components/EngagedSessionsChart';
import SourceMediumSessionsChart from "./components/SourceMediumSessionsChart";
import ChannelGroupEngagedSessionsChart from "./components/ChannelGroupEngagedSessionsChart"
import GenderTrafficChart from "./components/GenderTrafficChart"
import AgeTrafficChart from "./components/AgeTrafficChart";
import InterestTrafficChart from "./components/InterestTrafficChart"
import CreateSearch from './components/CreateSearch';
import KeywordsIdeas  from './components/KeywordsIdeas';
import KeywordsSuggestions   from './components/KeywordsSuggestions';
import CompetitorsKeywords from './components/CompetitorsKeywords';
import CompetitorsDomain from './components/CompetitorsDomain';
import PagesTrafficTable from './components/PagesTrafficTable';
import CountryTrafficTable from './components/CountryTrafficTable';
import DeviceTrafficTable from './components/DeviceTrafficTable';
import QueryTrafficTable from './components/QueryTrafficTable';
import KeywordsTable from './components/KeywordsTable'
import KeywordsSuggestionsTable from './components/KeywordsSuggestionsTable'
import CompetitorsKeywordsTable from './components/CompetitorsKeywordsTable';
import DomainCompetitorTable from "./components/DomainCompetitorTable";
import CompetitorsAnalysis from "./components/CompetitorsAnalysis";
import CreateTrends from "./components/CreateTrends";
import Chat from "./components/Chat";
import TrendsTable from "./components/TrendsTable";
import ImageLoadingSpinner from "./components/ImageLoadingSpinner";
import DirectMessage from './components/CreateDirectMessage';
import DirectMessageComponent from './components/DirectMessageComponent';
import ProfileSettings from './components/ProfileSettings';
import CompanySettings from "./components/CompanySettings";
import TargetSettings from "./components/TargetAudienceSettings";
import BrandVoice from "./components/BrandVoice";
import BrandStrategy from "./components/BrandStrategy";
import TargetAudience from "./components/TargetAudience";
import ProductList from "./components/ProductList";
import BrandKnowledge from "./components/BrandKnowledge";
import TopPerforming from "./components/TopPerforming";
import Dashboard from "./components/Dashboard";
import Favorites from "./components/Favorites";
import Prospects from "./components/Prospects";
import Radar from "./components/Radar"
import RadarProspects from "./components/RadarProspects"
// Import Images
import linkedinIcon from './assets/LinkedIn 4.png'
import copyIcon from './assets/CopyIcon.svg';
import editIcon from './assets/EditIcon.svg'
import gearIcon from "./assets/gear.svg"; // Import the gear image
import logo from './assets/logo.jpg'
import { useTemplate } from './components/TemplateContext'; 
import {handleCopy} from "./hooks/handleCopy";



// import '../styles/directMessageComponent.css';
import { ReactComponent as HeaderIcon } from './assets/headerIcon.svg';
import { ReactComponent as PencilIcon } from './assets/pencilIcon.svg';
import { ReactComponent as RequestIconEdit } from './assets/requestIconEdit.svg';
import { ReactComponent as CopyTemplate } from './assets/CopyTemplate.svg';
import { ReactComponent as EditTemplate } from './assets/EditTemplate.svg';
import { ReactComponent as Vector } from './assets/Vector.svg';




ChartJS.register(ArcElement, Tooltip, Legend);


function App() {
  const [selectedComponent, setSelectedComponent] = useState("Radar");
  const [responseData, setResponseData] = useState([]); // Store multiple responses
  const [responseDataIdea, setResponseDataIdea] = useState(null); // Store multiple responses
  const [loading, setLoading] = useState(null); // Handle loading state
  const [imageLoading, setImageLoading] = useState({}); // Track loading for each image separately
  const [refineTypes, setRefineTypes] = useState([]); // Store refine types
  const [showRefineOptions, setShowRefineOptions] = useState([]); // Manage visibility of refine options for each response
  const [csrfToken, setCsrfToken] = useState(''); // State for CSRF token
  const [requestCount, setRequestCount] = useState(1); // Track the number of requests
  const [userInfo, setUserInfo] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({ countryData: null, cityData: null });
  const [searchData, setSearchData] = useState(null);
  const [trendsData, setTrendsData] = useState(null);
  const [keywordData, setKeywordData] = useState(null);
  const [keywordCompetitorData, setKeywordCompetitorData] = useState(null);
  const [domainCompetitorData, setDomainCompetitorData] = useState(null);
  const [analysisCompetitorData, setAnalysisCompetitorData] = useState(null);
  const [keywordSuggestionData, setKeywordSuggestionData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const gearIconRef = useRef(null);
  const [generatedImages, setGeneratedImages] = useState({});
  const [activeHook, setActiveHook] = useState(false);
  const [activePost, setActivePost] = useState(false);
  const [editModes, setEditModes] = useState([]); // Track edit mode for each response
  const [editedContents, setEditedContents] = useState([]); // Track edited content for each response
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [selectedFavorite, setSelectedFavorite] = useState(null);
  const { selectedTemplate,Loading } = useTemplate();
    const [templateName, setTemplateName] = useState('');
    const [editableText, setEditableText] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const textareaRef = useRef(null);
    const { scale,setScale } = useTemplate();
      const [profileData, setProfileData] = useState({
      firstName: '',
      lastName: '',
      linkedInUrl: '',
      profilePicture: ''
    });
    

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert(`Copied to clipboard!`))
      .catch(err => console.error('Failed to copy text: ', err));
  };
  
  function isRtl(text) {
    // Regular expression to detect RTL characters
    const rtlChars = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F]/;
    return rtlChars.test(text);
  }
  
  const DynamicParameters = ({ insertParameter }) => {
    const parameters = [
      { key: '{Name}', desc: 'First name of the post writer.' },
      { key: '{Company}', desc: 'Company name of the post writer.' },
      { key: '{Role}', desc: 'Role of the post writer without the company name.' },
      { key: '{Post_Topic}', desc: 'Main post topic.' },
      { key: '{Post_Insight}', desc: 'Post insight that is relevant to you.' }
    ];
  
    return (
      <div className="dynamic-parameters">
        <h2>Dynamic Parameters</h2>
        <p>For personalization, parameters will be populated based on recipient & post</p>
        <hr className="header-divider" />
        <ul>
          {parameters.map(param => (
            <li key={param.key} onClick={() => insertParameter(param.key)}>
              <span className="parameter">{param.key}</span> - <span className="description">{param.desc}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };


    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('access_token');
          const response = await axios.get(`${API_URL}/users/profile/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setProfileData({
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            linkedInUrl: response.data.profile_url,
            profilePicture: response.data.profile_picture_url
          });
        } catch (error) {
          console.error('Error fetching profile data:', error.response);
        }
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      if (selectedTemplate) {
        setEditableText(selectedTemplate.content);
        setTemplateName(selectedTemplate.name);
        setScale(selectedTemplate.scale)
        console.log(selectedTemplate.content, "selectedtemplate",selectedTemplate.scale, "selectedtemplate scale")
        // setLoading('loading')
      }
    }, [selectedTemplate]);
  
    const insertParameter = (text) => {
      const textarea = textareaRef.current;
      if (textarea && document.activeElement === textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const textBefore = editableText.substring(0, start);
        const textAfter = editableText.substring(end, editableText.length);
        const newText = textBefore + text + textAfter;
        setEditableText(newText);
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = start + text.length;
        }, 0);
      } else {
        const newText = editableText + text;
        setEditableText(newText);
      }
    };
  
    const handleSaveTemplate = async () => {
      const token = localStorage.getItem('access_token');
      
      // Prepare the data to be sent to the backend
      const dataToSave = {
        name: templateName,
        content: editableText,
      };
    
      // Include the scale value in the request if it exists
      if (scale !== undefined) {
        dataToSave.scale = scale;  // Add the scale value to the request data
      }
    
      try {
        const response = await axios.post(`${API_URL}/marketing_content/api/create-dm-template/`, dataToSave, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        alert('Template saved successfully!');
        setTemplateName(''); // Optionally reset the template name after saving
      } catch (error) {
        console.error('Error saving the template:', error.response);
        alert('Failed to save template');
      }
    };
    
    const toggleEditing = () => {
      setIsEditing(!isEditing);
    };
  

  useEffect(() => {
    setEditedContents(responseData.map((response) => response.content || ""));
    setEditModes(new Array(responseData.length).fill(false));
  }, [responseData]);

  useEffect(() => {
    if (responseData && responseData.length > 0) {
      console.log("Clearing generated images because responseData changed.");
      setGeneratedImages([]); // Reset images when responseData changes
    }
  }, [responseData]);


  useEffect(() => {
    // Reset states with a delay for better UX, if needed
    setResponseData([]);
    setShowRefineOptions([]);
    setGeneratedImages([]);
    setImageLoading([]);
    setSelectedTrend(null);
    setSelectedFavorite(null);
  }, [selectedComponent]);

  useEffect(() => {
    if (selectedComponent === 'CreateAd') {
      setRequestCount(1);
    } else if (selectedComponent === 'CreateSocial' || selectedComponent === 'CreatePR') {
      setRequestCount(1);
    } else if (selectedComponent === 'CreateEmail') {
      setRequestCount(3);
    }
  }, [selectedComponent]);

  useEffect(() => {
    if (
        (selectedComponent === 'CreateImage' || selectedComponent === 'CreateImageAI') &&
        Array.isArray(responseData) &&
        responseData.length > 0
    ) {
      console.log("Stopping spinner because images are ready.");
      setLoading(null);
    }
  }, [responseData, selectedComponent]);



  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${API_URL}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserInfo(response.data); // Save user info (username, email, etc.)
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  // Fetch CSRF token from the backend
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/csrf-token/`);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);

  // Fetch refine types when the component mounts
  useEffect(() => {
    const fetchRefineTypes = async () => {
      try {
        // Retrieve the token from localStorage or any other secure storage
        const token = localStorage.getItem('access_token');

        const response = await axios.get(`${API_URL}/marketing_content/api/refine-types/`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Add the Authorization header
            'X-CSRFToken': csrfToken, // Include the CSRF token
          },
        });

        setRefineTypes(response.data); // Save the refine types in state
      } catch (error) {
        console.error('Error fetching refine types:', error.response || error);
      }
    };

    if (csrfToken) {
      fetchRefineTypes();
    }
  }, [csrfToken]);
  const handleTrendSelection = (trend) => {
    console.log("Trend selected:", trend); // Debugging
    setSelectedTrend(trend); // Save the selected trend
    setResponseData([]); // Reset previous responses

    setTimeout(() => {
      setResponseData([{ content: trend }]); // Store the selected trend in responseData
      setSelectedComponent("CreateSocial"); // Navigate to CreatePR
    }, 100);
  };

  const handleFavoriteSelection = (favorite) => {
    console.log("Favorite selected:", favorite);
    setResponseData([]);
    setTimeout(() => {
      setActivePost(true);
      setSelectedFavorite(favorite);
      setResponseDataIdea(favorite);
      setResponseData([{ favorite }]);
      setSelectedComponent("CreatePR");
      console.log("Favorite selected2:", responseDataIdea);
    }, 100);
  };

  // Separate functions for handling API calls for different components
  const handleCreateAdResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-post/`, formData);
  };

  const handleCreateSocialResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-brainstorm/`, formData);
  };

  const handleCreatePRResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-hook/`, formData);
  };
  const handleCreateEmailResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-dm/`, formData);
  };
  const handleCreateEcommerceResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-ecommerce/`, formData);
  };
  const handleCreateGeneralResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-general/`, formData);
  };
  const handleCreateRefineResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-refine/`, formData);
  };
  const handleCreateBoostResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-content-booster/`, formData);
  };
  const handleCreateTranslateResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/translate-content/`, formData);
  };
  const handleCreateImageResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-image/`, formData);
  };

  const handleCreateImageAIResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-image-ai-studio/`, formData);
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
          toast.success("Content copied to clipboard!");
        })
        .catch((error) => {
          console.error("Failed to copy text:", error);
          toast.error("Failed to copy content. Please try again.");
        });
  };
  const handleEditClick = (index) => {
    setEditModes((prev = []) =>
        prev.map((mode, i) => (i === index ? !mode : mode))
    );
  };

  const handleSaveEdit = (index) => {
    setResponseData((prev) =>
        prev.map((data, i) =>
            i === index ? { ...data, content: editedContents[index] } : data
        )
    );
    setEditModes((prev = []) =>
        prev.map((mode, i) => (i === index ? false : mode))
    );
  };

  const handleAnalyticsData = (data) => {
    console.log("Setting analyticsData in state:", data);
    setAnalyticsData({
      countryData: data.countryData,
      cityData: data.returningUsersData,
      landingPageData: data.landingPageData,
      landingPageFilterData: data.landingPageFilterData,
      landingPageBounceRateData: data.landingPageBounceRateData,
      landingPageTitleData: data.landingPageTitleData,
      landingSourceMediumData: data.landingSourceMediumData,
      landingDefaultChannelData: data.landingDefaultChannelData,
      GenderTrafficData: data.GenderTrafficData,
      AgeTrafficData: data.AgeTrafficData,
      InterestTrafficData: data.InterestTrafficData
    });
  };

  const handleGenerateHook = (content, activeHook = false) => {
    setResponseData([]); // Reset first
    setTimeout(() => {
      setResponseDataIdea(content);
      setResponseData([{ content }]); // Update responseData
      setActiveHook(activeHook)
      setSelectedComponent("CreatePR"); // Switch to CreatePR
    }, 100);
  };

  const handleGeneratePost = (content, activePost = false) => {
    // Navigate to CreatePR
    setResponseData([]); // Reset first
    setTimeout(() => {
      setResponseData([{ content }]); // Update after a small delay
      setActivePost(activePost)
      setSelectedComponent("CreateAd");
    }, 0);
  };


  const handleSearchData = (data) => {
    console.log("Setting searchData in state:", data);
    setSearchData({
      queriesData: data.queriesData,
      pagesData: data.pagesData,
      countriesData: data.countriesData,
      devicesData: data.devicesData,
    });
  };

  const handleTrendsData = (data) => {
    console.log("Setting searchData in state:", data);
    setTrendsData({
      trendsData: data.trendsData,
    });
  };

  const handleFavoritesData = (data) => {
    console.log("Setting searchData in state:", data);
    setTrendsData({
      favoritesData: data.favoritesData,
    });
  };


  const handleKeywordsData = (data) => {
    console.log("Keywords data received in handleKeywordsData:", data);
    setKeywordData({
      keywordsData: data,
    });
  };

  const handleKeywordsSuggestionData = (data) => {
    console.log("Keywords data received in handleKeywordsSuggestionData:", data);
    setKeywordSuggestionData({
      keywordsSuggestionData: data.suggestionsData,
    });
  };
  const handleKeywordsCompetitorData = (data) => {
    console.log("Keywords data received in handleKeywordsCompetitorData:", data);
    setKeywordCompetitorData({
      keywordCompetitorData: data.competitorsData,
    });
  };

  const handleDomainCompetitorData = (data) => {
    console.log("Keywords data received in handleDomainCompetitorData:", data);
    setDomainCompetitorData({
      domainCompetitorData: data.domainCompetitorData,
    });
  };

  const handleAnalysisCompetitorData = (data) => {
    console.log("Keywords data received in handleAnalysisCompetitorData:", data);
    setAnalysisCompetitorData(data); // Set the data directly
  };

  const handleGearPress = () => {
    if (showDropdown) {
      // When dropdown is being closed, blur the gear button to remove focus
      if (gearIconRef.current) {
        gearIconRef.current.blur();
      }
    }
  }

  const handleGearClick = () => {
    setShowDropdown((prev) => !prev); // Toggle dropdown visibility
  }

  useEffect(() => {
    // Function to handle clicks outside
    const handleClickOutside = (event) => {
      // If both refs are valid, and the click is outside both elements
      if (dropdownRef.current &&
          gearIconRef.current &&
          !dropdownRef.current.contains(event.target) &&
          !gearIconRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Add event listener when dropdown is shown
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup function
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]); // Re-run effect when showDropdown changes

  const handleProfileEdit = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/users/settings/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch settings: ${response.status}`);
      }

      const data = await response.json();
      setResponseData([data]);
      setSelectedComponent('ProfileSettings');
      setShowDropdown(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleCompanyEdit = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/users/company-settings/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch settings: ${response.status}`);
      }

      const data = await response.json();
      setResponseData([data]);
      setSelectedComponent('CompanySettings');
      setShowDropdown(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleTargetEdit = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_URL}/users/target-audience-settings/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch settings: ${response.status}`);
      }

      const data = await response.json();
      setResponseData([data]);
      setSelectedComponent('TargetSettings');
      setShowDropdown(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      await fetch(`${API_URL}/users/logout/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const makeApiRequests = async (apiEndpoint, formData) => {
    try {
      console.log("Starting API request...");

      // Retrieve the token from localStorage or any other secure storage
      const token = localStorage.getItem('access_token');

      // Create an array of API requests based on the requestCount
      const requests = Array.from({ length: requestCount }, (_, index) => {
        console.log(`Creating request ${index + 1}`);
        const formDataFormatted = new URLSearchParams();
        for (let key in formData) {
          formDataFormatted.append(key, formData[key]);
        }
        return axios.post(
            apiEndpoint,
            formDataFormatted,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': csrfToken,
                'Authorization': `Bearer ${token}`,
              },
            }
        );
      });

      console.log("All requests created. Sending requests...");

      // Wait for all requests to complete
      const responses = await Promise.all(requests);
      console.log("All requests completed.");

      let responseDataArray = [];

      responses.forEach((response, index) => {
        console.log(`Response ${index + 1}:`, response.data);

        // If the response is an array and contains objects, spread its contents
        if (Array.isArray(response.data) && response.data.length > 0 && typeof response.data[0] === 'object') {
          console.log(`Response ${index + 1} is an array, spreading its contents.`);
          responseDataArray.push(...response.data);
        } else {
          responseDataArray.push(response.data);
        }
      });

      console.log("Final API responses:", responseDataArray);

      if (selectedComponent === 'CreateImage' || selectedComponent === 'CreateImageAI') {
        console.log("Handling CreateImage or CreateImageAI responses...");
        setResponseData(responseDataArray);
      } else {
        console.log("Handling other component responses...");
        responseDataArray.forEach((response, index) => {
          console.log("HANNA", response);
          if (response.id) {
            console.log(`Response at index ${index} has ID: ${response.id}`);
          } else {
            console.warn(`No ID found in response at index ${index}. Response data:`, response);
          }
        });
        setResponseData(responseDataArray);
      }

      setShowRefineOptions(Array(responseDataArray.length).fill(false));
      console.log("Setting loading state to false...");
      setLoading(null);
      console.log("Loading state set to false");
    } catch (error) {
      console.error('Error submitting multiple requests:', error.response || error);
      console.log("An error occurred, setting loading state to false");
      setLoading(null);
    }
  };

  const componentToTitle = {
    CreateAd: 'Post',
    CreateSocial: 'Brainstorm content ideas',
    CreatePR: 'Hooks & Rehooks',
    CreateEmail: 'Direct Messages',
    Favorites: "Favorites",
    BrandVoice: "AI Personalization",
    Dashboard: "Dashboard",
    Radar: "Posts Radar",
    RadarProspects: "Prospects Radar",
    CreateDM: "DM Template Creation & Management",
    Prospects: "LinkedIn Prospects",
    CreateImage: "Create stock images",
    CreateImageAI: "Create AI images",
  }

  const renderUserInfo = (settings) => (
      userInfo ? (
          <div className="user-info">
            <span className="page-title">{componentToTitle[selectedComponent]}</span>
            {/* Add download link */}
            <a
                href="https://chromewebstore.google.com/detail/brandwizard/dppihnahbedndhhkdfehhbekopfjmgbd?authuser=0&hl=en"
                className="download-extension-link"
                tabIndex="0"
            >
              Install our LinkedIn Chrome extension
            </a>

            <span>Welcome, {userInfo.first_name || userInfo.username}</span>
            <button className="gear-button"
                    onMouseUp={handleGearPress} /* This is to avoid blur on keyboard press */
                    onClick={handleGearClick}
                    ref={gearIconRef}
            >
              <img
                  src={gearIcon}
                  alt="Settings"
                  className="gear-icon"
              />
            </button>
            {showDropdown && (
                <div className="dropdown-settings" ref={dropdownRef}>
                  {/*<button className="dropdown-setting" onClick={handleProfileEdit}>*/}
                  {/*  Profile Edit*/}
                  {/*</button>*/}
                  {/*<button className="dropdown-setting" onClick={handleCompanyEdit}>*/}
                  {/*  Company Edit*/}
                  {/*</button>*/}
                  {/*<button className="dropdown-setting" onClick={handleTargetEdit}>*/}
                  {/*  Target audience Edit*/}
                  {/*</button>*/}
                  <button className="dropdown-setting" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
            )}
          </div>
      ) : (
          <div className="user-info">Loading user info...</div>
      )
  );

  const handleResponse = (formData) => {
    if (selectedComponent === 'CreateAd') {
      setKeywordData(null);
      handleCreateAdResponse(formData);
    } else if (selectedComponent === 'CreateSocial') {
      setKeywordData(null);
      handleCreateSocialResponse(formData);
    } else if (selectedComponent === 'CreatePR') {
      setKeywordData(null);
      handleCreatePRResponse(formData);
    } else if (selectedComponent === 'CreateEmail') {
      handleCreateEmailResponse(formData);
      setKeywordData(null);
    } else if (selectedComponent === 'CreateEcommerce') {
      setKeywordData(null);
      handleCreateEcommerceResponse(formData);
    }
    else if (selectedComponent === 'CreateGeneral') {
      setKeywordData(null);
      handleCreateGeneralResponse(formData);
    }
    else if (selectedComponent === 'CreateRefine') {
      setKeywordData(null);
      handleCreateRefineResponse(formData);
    }
    else if (selectedComponent === 'CreateBoost') {
      setKeywordData(null);
      handleCreateBoostResponse(formData);
    }
    else if (selectedComponent === 'CreateTranslate') {
      setKeywordData(null);
      handleCreateTranslateResponse(formData);
    }
    else if (selectedComponent === 'CreateImage') {
      setKeywordData(null);
      handleCreateImageResponse(formData);
    } else if (selectedComponent === 'CreateImageAI') {
      setKeywordData(null);
      handleCreateImageAIResponse(formData);
    }
  };


  const setEditModeForIndex = (index, value) => {
    setEditModes((prevModes) => {
      const updatedModes = [...prevModes];
      updatedModes[index] = value;
      return updatedModes;
    });
  };

  const setEditedContentForIndex = (index, value) => {
    setEditedContents((prevContents) => {
      const updatedContents = [...prevContents];
      updatedContents[index] = value;
      return updatedContents;
    });
  };

  const handleCancelEdit = (index) => {
    setEditedContents((prev = []) =>
        prev.map((content, i) =>
            i === index ? responseData[index].content : content
        )
    );
    setEditModes((prev = []) =>
        prev.map((mode, i) => (i === index ? false : mode))
    );
  };

  const handleMagicBoost = async (index) => {
    setGeneratedImages([]);
    const id = responseData[index]?.id;
    if (!id) return;

    let apiEndpoint;

    // Match selectedComponent to the correct API endpoint
    switch (selectedComponent) {
      case 'CreateAd':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-post/${id}/`;
        break;
      case 'CreateSocial':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-social/${id}/`;
        break;
      case 'CreateBoost':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-content/${id}/`;
        break;
      case 'CreateEcommerce':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-ecommerce/${id}/`;
        break;
      case 'CreateEmail':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-email/${id}/`;
        break;
      case 'CreateGeneral':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-general/${id}/`;
        break;
      case 'CreateRefine':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-refine/${id}/`;
        break;
      case 'CreateTranslate':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-translate/${id}/`;
        break;
      case 'CreatePR':
        apiEndpoint = `${API_URL}/marketing_content/api/boost-pr/${id}/`;
        break;
      default:
        console.error('Unknown selectedComponent:', selectedComponent);
        return;
    }

    try {
      setLoading("Loading");

      const token = localStorage.getItem('access_token');

      const response = await axios.patch(apiEndpoint, {}, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${token}`,
        },
      });

      // Update responseData with the new boosted content or scale
      setResponseData((prevData) =>
          prevData.map((data, i) =>
              i === index
                  ? { ...data, content: response.data.content || data.content, scale: response.data.scale || data.scale }
                  : data
          )
      );
    } catch (error) {
      console.error('Error boosting content:', error.response || error);
    } finally {
      setLoading(null);
    }
  };

  const handleGenerateImage = async (index) => {
    const id = responseData[index]?.id;
    if (!id) return;

    try {
      // Start loading only for this specific index
      setImageLoading((prev) => ({ ...prev, [index]: true }));

      const token = localStorage.getItem('access_token');

      // Make API request to generate image
      const response = await axios.post(
          `${API_URL}/marketing_content/api/create-image-ai/`,
          new URLSearchParams({ description: responseData[index].content }),
          {
            headers: {
              'X-CSRFToken': csrfToken,
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
      );

      // Update the generated images state
      setGeneratedImages((prevImages) => ({
        ...prevImages,
        [index]: response.data, // Store images array
      }));
    } catch (error) {
      console.error('Error generating image:', error.response || error);
    } finally {
      // Stop loading only for this specific index
      setImageLoading((prev) => ({ ...prev, [index]: false }));
    }
  };


  const handleRefine = async (index, refinementType) => {
    setGeneratedImages([]);
    const id = responseData[index]?.id;
    if (!id) return;

    const apiEndpoint = selectedComponent === 'CreateAd'
        ? `${API_URL}/marketing_content/api/refine-post/${id}/${encodeURIComponent(refinementType)}`
        : selectedComponent === 'CreateSocial'
            ? `${API_URL}/marketing_content/api/refine-social/${id}/${encodeURIComponent(refinementType)}`
            : selectedComponent === 'CreateBoost'
                ? `${API_URL}/marketing_content/api/refine-content/${id}/${encodeURIComponent(refinementType)}`
                : selectedComponent === 'CreateEcommerce'
                    ? `${API_URL}/marketing_content/api/refine-ecommerce/${id}/${encodeURIComponent(refinementType)}`
                    : selectedComponent === 'CreateEmail'
                        ? `${API_URL}/marketing_content/api/refine-email/${id}/${encodeURIComponent(refinementType)}`
                        : selectedComponent === 'CreateGeneral'
                            ? `${API_URL}/marketing_content/api/refine-general/${id}/${encodeURIComponent(refinementType)}`
                            : selectedComponent === 'CreateRefine'
                                ? `${API_URL}/marketing_content/api/refine-refine/${id}/${encodeURIComponent(refinementType)}`
                                : selectedComponent === 'CreateSocial'
                                    ? `${API_URL}/marketing_content/api/refine-social/${id}/${encodeURIComponent(refinementType)}`
                                    : selectedComponent === 'CreateTranslate'
                                        ? `${API_URL}/marketing_content/api/refine-translate/${id}/${encodeURIComponent(refinementType)}`
                                        : `${API_URL}/marketing_content/api/refine-pr/${id}/${encodeURIComponent(refinementType)}`;

    try {
      setLoading("Loading");

      // Retrieve the token from localStorage or any other secure storage
      const token = localStorage.getItem('access_token');

      const response = await axios.patch(apiEndpoint, {}, {
        headers: {
          'X-CSRFToken': csrfToken, // Include the CSRF token
          'Authorization': `Bearer ${token}`, // Add the Authorization header
        },
      });

      setResponseData((prevData) =>
          prevData.map((data, i) =>
              i === index ? { ...data, content: response.data.content || data.content, scale: response.data.scale || data.scale } : data
          )
      );
    } catch (error) {
      console.error('Error refining:', error.response || error);
    } finally {
      setLoading(null);
    }
  };

  const renderSelectedComponent = () => {
    if (selectedComponent === 'CreateAd') {
      return (
          <CreateAd
              responseDataIdea={responseDataIdea}
              responseData={responseData}
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
              activePost={activePost}
          />
      );
    } else if (selectedComponent === 'CreateSocial') {
      return (
          <CreateSocial
              selectedTrend={selectedTrend}
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    } else if (selectedComponent === 'CreatePR') {
      return (
          <CreatePR
              responseData={responseData}
              selectedFavorite={selectedFavorite}
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
              activeHook={activeHook}
          />
      );
    } else if (selectedComponent === 'CreateEmail') {
      return (
          <CreateEmail
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    } else if (selectedComponent === 'CreateEcommerce') {
      return (
          <CreateEcommerce
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    }
    else if (selectedComponent === 'CreateGeneral') {
      return (
          <CreateGeneral
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    }
    else if (selectedComponent === 'CreateRefine') {
      return (
          <CreateRefine
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    }
    else if (selectedComponent === 'CreateTranslate') {
      return (
          <CreateTranslate
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
          />
      );
    }
    else if (selectedComponent === 'CreateBoost') {
      return (
          <CreateBoost
              onResponse={handleResponse}
              onLoading={setLoading}
              onSetRequestCount={setRequestCount}
              onKeywordsFetched={handleKeywordsData}
          />
      );
    }
    else if (selectedComponent === 'CreateAnalytics') {
      return (
          <CreateAnalytics
              onFetchDataComplete={handleAnalyticsData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'CreateSearch') {
      return (
          <CreateSearch
              onFetchDataComplete={handleSearchData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'CreateTrends') {
      return (
          <CreateTrends
              onFetchDataComplete={handleTrendsData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'KeywordsIdeas') {
      console.log("Rendering KeywordsIdeas component"); // Log for rendering confirmation
      return (
          <KeywordsIdeas
              onFetchDataComplete={handleKeywordsData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'KeywordsSuggestions') {
      console.log("Rendering KeywordsSuggestions component"); // Log for rendering confirmation
      return (
          <KeywordsSuggestions
              onFetchDataComplete={handleKeywordsSuggestionData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'CompetitorsKeywords') {
      console.log("Rendering CompetitorsKeywords component"); // Log for rendering confirmation
      return (
          <CompetitorsKeywords
              onFetchDataComplete={handleKeywordsCompetitorData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'CompetitorsDomain') {
      console.log("Rendering CompetitorsDomain component"); // Log for rendering confirmation
      return (
          <CompetitorsDomain
              onFetchDataComplete={handleDomainCompetitorData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }
    else if (selectedComponent === 'CompetitorsAnalysis') {
      console.log("Rendering CompetitorsAnalysis component"); // Log for rendering confirmation
      return (
          <CompetitorsAnalysis
              onFetchDataComplete={handleAnalysisCompetitorData} // Pass callback here
              csrfToken={csrfToken}
          />
      );
    }

    else if (selectedComponent === 'CreateImage') {
      return <CreateImage onResponse={handleResponse} onLoading={setLoading} />;
    }
    else if (selectedComponent === 'CreateImageAI') {
      return <CreateImageAI onResponse={handleResponse} onLoading={setLoading} />;
    }
    else if (selectedComponent === 'CreateDM') {
      return <DirectMessage />;
    }
    return null;
  };

  const renderChart = (scale) => {
    const data = {
      datasets: [
        {
          data: [scale, 100 - scale],
          backgroundColor: ['#BC43FF', '#f8eaff'],
          borderWidth: 2,
        },
      ],
    };

    const options = {
      cutout: '70%',
      responsive: true,
      plugins: {
        legend: { display: false },
      },
    };

    return (
        <div className="chart-wrapper">
          <Doughnut data={data} options={options} width={90} height={90} />
        </div>
    );
  };

  return (
      <div className="app-container">
        <Sidebar setComponent={(component) => {
          setActiveHook(false);  // ✅ Reset activeHook on any component change
          setActivePost(false);
          if (component === selectedComponent) {
            setSelectedComponent(null);  // Reset active component to emulate a refresh
            setTimeout(() => {  // Necessary because of React's state-change batching
              setSelectedComponent(component);
            }, 0);
          }
          else setSelectedComponent(component); // ✅ Switch component
        }} selectedComponent={selectedComponent} />

        <div
            className={`content ${
                selectedComponent === "Chat" || selectedComponent === "BrandVoice" || selectedComponent === "BrandStrategy" || selectedComponent === "TargetAudience" || selectedComponent === "ProductList" || selectedComponent === "BrandKnowledge" || selectedComponent === "TopPerforming" || selectedComponent === "Dashboard" || selectedComponent === "Favorites" || selectedComponent === "Radar" || selectedComponent === "RadarProspects" || selectedComponent === "Prospects"
                    ? "hidden"
                    : selectedComponent &&
                    selectedComponent !== "ProfileSettings" &&
                    selectedComponent !== "CompanySettings" &&
                    selectedComponent !== "TargetSettings"

                        ? "visible"
                        : responseData.length > 0 &&
                        selectedComponent !== "ProfileSettings" &&
                        selectedComponent !== "CompanySettings" &&
                        selectedComponent !== "TargetSettings"
                            ? "visible"
                            : "hidden"
            }`}
        >
          {selectedComponent !== "ProfileSettings" &&
              selectedComponent !== "CompanySettings" &&
              selectedComponent !== "TargetSettings" &&
              renderSelectedComponent()}
        </div>

        <div className="main-section">
          {renderUserInfo()}
          <div className="response-section">
            {loading ? (
                <ImageLoadingSpinner message={loading} />
            ) : responseData.length > 0 && selectedComponent === 'ProfileSettings' ? ( // Add ProfileSettings here
                <ProfileSettings data={responseData[0]}/>
            ) : responseData.length > 0 && selectedComponent === 'CompanySettings' ? ( // Add ProfileSettings here
                <CompanySettings data={responseData[0]}/>
            ) : responseData.length > 0 && selectedComponent === 'TargetSettings' ? ( // Add ProfileSettings here
                <TargetSettings data={responseData[0]}/>
            ) : selectedComponent === 'Chat' ? (
                <Chat
                    onClose={() => setSelectedComponent("Radar")}
                    csrfToken={csrfToken}
                />
            ) : selectedComponent === 'Dashboard' ? (
                <Dashboard
                    csrfToken={csrfToken}
                    onTrendSelect={handleTrendSelection}
                />
            ) : selectedComponent === 'Favorites' ? (
                <Favorites
                    csrfToken={csrfToken}
                    onFavoriteSelect={handleFavoriteSelection}
                />
            ) : selectedComponent === 'Prospects' ? (
                <Prospects csrfToken={csrfToken} />
            ): selectedComponent === 'Radar' ? (
                <Radar
                    csrfToken={csrfToken}
                    onFavoriteSelect={handleFavoriteSelection}
                />
            ): selectedComponent === 'RadarProspects' ? (
                <RadarProspects
                    csrfToken={csrfToken}
                    onFavoriteSelect={handleFavoriteSelection}
                />
            ): selectedComponent === 'BrandVoice' ? (
                <BrandVoice csrfToken={csrfToken} />
            ) : selectedComponent === 'BrandStrategy' ? (
                <BrandStrategy csrfToken={csrfToken} />
            ) : selectedComponent === 'TargetAudience' ? (
                <TargetAudience csrfToken={csrfToken} />
            ) : selectedComponent === 'ProductList' ? (
                <ProductList csrfToken={csrfToken} />
            ) : selectedComponent === 'BrandKnowledge' ? (
                <BrandKnowledge csrfToken={csrfToken} />
            ) : selectedComponent === 'TopPerforming' ? (
                <TopPerforming csrfToken={csrfToken} />
          
            ) : selectedComponent === 'CreateAnalytics' && analyticsData ? (
                <>
                  <div className="response-box">
                    <h3>Country Traffic</h3>
                    <CountryAnalyticsChart countryData={analyticsData.countryData}/>
                  </div>
                  <div className="response-box">
                    <h3>City Returning Users</h3>
                    <CityAnalyticsChart cityData={analyticsData.cityData}/>
                  </div>
                  <div className="response-box">
                    <h3>Landing page</h3>
                    <LandingPageSessionsChart landingPageData={analyticsData.landingPageData}/>
                  </div>
                  <div className="response-box">
                    <h3>Landing page filtered</h3>
                    <LandingPageSessionsChart landingPageData={analyticsData.landingPageFilterData}/>
                  </div>
                  <div className="response-box">
                    <h3>Landing page bounce</h3>
                    <LandingPageBounceChart landingPageData={analyticsData.landingPageBounceRateData}/>
                  </div>
                  <div className="response-box">
                    <h3>Engaged Sessions by Page Title</h3>
                    <EngagedSessionsChart sessionData={analyticsData.landingPageTitleData}/>
                  </div>
                  <div className="response-box">
                    <h3>Sessions by Source/Medium</h3>
                    <SourceMediumSessionsChart sourceMediumData={analyticsData.landingSourceMediumData}/>
                  </div>
                  <div className="response-box">
                    <h3>Engaged Sessions by Default Channel Group</h3>
                    <ChannelGroupEngagedSessionsChart channelGroupData={analyticsData.landingDefaultChannelData}/>
                  </div>
                  <div className="response-box">
                    <h3>Gender traffic</h3>
                    <GenderTrafficChart data={analyticsData.GenderTrafficData}/>
                  </div>
                  <div className="response-box">
                    <h3>Age traffic</h3>
                    <AgeTrafficChart data={analyticsData.AgeTrafficData}/>
                  </div>
                  <div className="response-box">
                    <h3>Interest traffic</h3>
                    <InterestTrafficChart data={analyticsData.InterestTrafficData}/>
                  </div>
                </>
            ) : selectedComponent === 'CreateSearch' && searchData ? (
                <>
                  <div className="response-box">
                    <h3>Top Queries</h3>
                    {searchData.queriesData && searchData.queriesData.length > 0 ? (
                        <QueryTrafficTable data={searchData.queriesData}/>
                    ) : (
                        <p>No data available for queries.</p>
                    )}
                  </div>

                  <div className="response-box">
                    <h3>Top Pages</h3>
                    {searchData.pagesData && searchData.pagesData.length > 0 ? (
                        <PagesTrafficTable data={searchData.pagesData}/>
                    ) : (
                        <p>No data available for pages.</p>
                    )}
                  </div>

                  <div className="response-box">
                    <h3>Top Countries</h3>
                    {searchData.countriesData && searchData.countriesData.length > 0 ? (
                        <CountryTrafficTable data={searchData.countriesData}/>
                    ) : (
                        <p>No data available for countries.</p>
                    )}
                  </div>

                  <div className="response-box">
                    <h3>Top Devices</h3>
                    {searchData.devicesData && searchData.devicesData.length > 0 ? (
                        <DeviceTrafficTable data={searchData.devicesData}/>
                    ) : (
                        <p>No data available for devices.</p>
                    )}
                  </div>
                </>
            ) : (selectedComponent === 'CreateAd' || selectedComponent === 'CreateSocial' || selectedComponent === 'CreatePR' || selectedComponent === 'CreateEmail' || selectedComponent === 'CreateEcommerce' || selectedComponent === 'CreateGeneral' || selectedComponent === 'CreateRefine' || selectedComponent === 'CreateBoost' || selectedComponent === 'KeywordsIdeas') && keywordData ? (
                <div className="response-box">
                  <h3>Keywords</h3>
                  {keywordData ? (
                      <KeywordsTable data={keywordData}/>
                  ) : (
                      <p>No data available for keywords ideas.</p>
                  )}
                </div>
            ) : selectedComponent === 'KeywordsSuggestions' && keywordSuggestionData ? (
                <div className="response-box">
                  <h3>Keywords Suggestions Data</h3>
                  <div className="response-box">
                    {keywordSuggestionData ? (
                        <KeywordsSuggestionsTable data={keywordSuggestionData.keywordsSuggestionData}/>
                    ) : (
                        <p>No data available for keyword suggestions.</p>
                    )}
                  </div>
                </div>
            ) : selectedComponent === 'CompetitorsKeywords' && keywordCompetitorData ? (
                <div className="response-box">
                  <h3>Competitors Keywords</h3>
                  {keywordCompetitorData ? (
                      <CompetitorsKeywordsTable data={keywordCompetitorData.keywordCompetitorData}/>
                  ) : (
                      <p>No data available for competitors keywords.</p>
                  )}
                </div>
            ) : selectedComponent === 'CompetitorsDomain' && domainCompetitorData ? (
                <div className="response-box">
                  <h3>Competitors Domain</h3>
                  {domainCompetitorData ? (
                      <DomainCompetitorTable data={domainCompetitorData.domainCompetitorData}/>
                  ) : (
                      <p>No data available for competitors domain.</p>
                  )}
                </div>
            ) : selectedComponent === 'CompetitorsAnalysis' && analysisCompetitorData ? (
                <div className="response-box">
                  <h3>Competitors Analysis</h3>
                  <pre className="styled-pre">{analysisCompetitorData}</pre>
                  {/* Display as plain text */}
                </div>
            ) : selectedComponent === 'CreateTrends' && trendsData ? (
                <div className="response-box">
                  <h3>Google trends</h3>
                  <TrendsTable data={trendsData.trendsData}/>
                </div>
            ) : responseData.length ? (
                <div className="response-box">
                  {responseData.map((response, index) => (
                      <div key={index} className={selectedComponent ==="CreateAd" ? "response-item" : "response-item multiple"}>
                        {selectedComponent === 'CreateImage' || selectedComponent === 'CreateImageAI' ? (
                            Array.isArray(response) ? (
                                <div className="image-gallery">
                                  {response.map((url, idx) => (
                                      <img key={idx} src={url} alt={`Generated ${idx}`} className="generated-image"/>
                                  ))}
                                </div>
                            ) : (
                                <p>No images available.</p>
                            )
                        ) : (
                            <div className="response-wrapper">
                              {(() => {
                                const isEditing = editModes[index] || false;
                                const editedContent = editedContents[index] || response.content;

                                return isEditing ? (
                                    <div className="edit-mode">
                                      <textarea
                                          className="edit-textarea"
                                          value={editedContent}
                                          onChange={(e) =>
                                              setEditedContents((prev) => {
                                                const updated = [...prev];
                                                updated[index] = e.target.value;
                                                return updated;
                                              })
                                          }
                                      />
                                      <div className="edit-actions">
                                        <button onClick={() => handleSaveEdit(index, editedContent, setEditModes)} className="save-edit-button">
                                          Save
                                        </button>
                                        <button onClick={() => handleCancelEdit(index, response.content, setEditModes, setEditedContents)} className="cancel-edit-button">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                ) : (
                                    <div className={selectedComponent === "CreateAd" ? "response-container post-container" : "response-container"}>
                                      {selectedComponent === "CreateAd" && (
                                          <div className="response-header">
                                            <button onClick={() => handleCopy(response.content)} className="copy-button">
                                              <img src={copyIcon} alt="Copy Icon" className="copy-icon"/>
                                            </button>
                                            {!editModes[index] && (
                                                <button
                                                    onClick={() =>
                                                        setEditModes((prev) => {
                                                          const updated = [...prev];
                                                          updated[index] = true;
                                                          return updated;
                                                        })
                                                    }
                                                    className="edit-button"
                                                >
                                                  <img src={editIcon} alt="Edit Icon" className="edit-icon"/>
                                                </button>
                                            )}
                                          </div>)}
                                      <pre
                                          className="styled-pre"
                                          style={{
                                            direction:
                                                response.text_direction === "Right-to-Left" ||
                                                response.text_direction === "Right-to-Left Language"
                                                    ? "rtl"
                                                    : "ltr",
                                            textAlign:
                                                response.text_direction === "Right-to-Left" ||
                                                response.text_direction === "Right-to-Left Language"
                                                    ? "right"
                                                    : "left",
                                          }}
                                      >
                                        {response.content}
                                      </pre>
                                    </div>
                                );
                              })()}

                              {/*{!editModes[index] && (*/}
                              {/*    <button onClick={() => setEditModes((prev) => {*/}
                              {/*      const updated = [...prev];*/}
                              {/*      updated[index] = true;*/}
                              {/*      return updated;*/}
                              {/*    })} className="edit-button">*/}
                              {/*      Edit*/}
                              {/*    </button>*/}
                              {/*)}*/}
                              {/* Footer: Chart and Buttons */}
                              <div className={selectedComponent ==="CreateAd" ? "response-side post-side" : "response-side"}>
                                <div className={selectedComponent ==="CreateAd" ? "response-footer post-footer" : "response-footer"}>
                                  {/* Chart Section */}
                                  {response.scale && (
                                      <div className="response-chart">
                                        <div className="chart-container">
                                          {renderChart(response.scale)}
                                          <img src={linkedinIcon} alt="LinkedIn" className="chart-icon"/>
                                          <p className="scale-value">{response.scale}%</p>
                                        </div>
                                      </div>
                                  )}

                                  {/* Action Buttons */}
                                  <div className="response-actions">
                                    {selectedComponent === "CreateAd" && (
                                        <>
                                          <button onClick={() => handleMagicBoost(index)} className="magic-boost-button">
                                            Boost
                                          </button>
                                          <button
                                              onClick={() =>
                                                  setShowRefineOptions((prev) =>
                                                      prev.map((value, i) => (i === index ? !value : value))
                                                  )
                                              }
                                              className="magic-boost-button"
                                          >
                                            Refine
                                          </button>
                                          <button onClick={() => handleGenerateImage(index)} className="magic-boost-button">
                                            Generate Image
                                          </button>

                                          {/* Spinner for image loading */}
                                        </>
                                    )}

                                    {/* Generate Hook Button */}
                                    {selectedComponent === "CreateSocial" && (
                                        <button onClick={() => handleGenerateHook(response.content, true)}
                                                className="magic-boost-button">
                                          Generate Hook
                                        </button>
                                    )}
                                    {selectedComponent === "CreatePR" && (
                                        <button onClick={() => handleGeneratePost(response.content, true)}
                                                className="magic-boost-button">
                                          Generate Post
                                        </button>
                                    )}

                                    {selectedComponent !== "CreateAd" && (
                                        <>
                                          <button onClick={() => handleCopy(response.content)} className="copy-button">
                                            <img src={copyIcon} alt="Copy Icon" className="copy-icon"/>
                                          </button>
                                          {!editModes[index] && (
                                              <button
                                                  className="edit-button"
                                                  onClick={() =>
                                                      setEditModes((prev) => {
                                                        const updated = [...prev];
                                                        updated[index] = true;
                                                        return updated;
                                                      })
                                                  }
                                              >
                                                <img src={editIcon} alt="Edit Icon" className="edit-icon"/>
                                              </button>
                                          )}
                                        </>
                                    )}
                                  </div>

                                  {/* Copy Button */}

                                </div>
                                {imageLoading[index] ? (
                                    <div className="image-loading-indicator">
                                      <div className="dot"></div>
                                      <div className="dot"></div>
                                      <div className="dot"></div>
                                      <p>Generating images...</p>
                                    </div>
                                ) : (
                                    // Images section with AI and stock photos
                                    generatedImages[index] && (
                                        <div className="images-section">
                                          {/* AI Images */}
                                          <h4>AI images created for your post</h4>
                                          <div className="image-gallery">
                                            {generatedImages[index]?.slice(0, 3).map((url, imgIdx) => (
                                                <img
                                                    key={imgIdx}
                                                    src={url}
                                                    alt={`AI Generated ${imgIdx}`}
                                                    className="generated-image"
                                                />
                                            ))}
                                          </div>

                                          {/* Suggested Stock Photos */}
                                          <h4>Suggested stock photos</h4>
                                          <div className="image-gallery">
                                            {generatedImages[index]?.slice(3, 6).map((url, imgIdx) => (
                                                <img
                                                    key={imgIdx}
                                                    src={url}
                                                    alt={`Stock ${imgIdx}`}
                                                    className="generated-image"
                                                />
                                            ))}
                                          </div>
                                        </div>
                                    )
                                )}
                              </div>
                            </div>
                        )}

                        {/* Refine Options */}
                        {showRefineOptions[index] && (
                            <div className="refine-options">
                              {refineTypes.map((type) => (
                                  <button
                                      key={type.value}
                                      onClick={() => handleRefine(index, type.label)}
                                      className="refine-option"
                                  >
                                    {type.label}
                                  </button>
                              ))}
                            </div>
                        )}
                      </div>

                  ))}
                </div>
            ) : !selectedComponent ? (
                <div></div>
            ) : (
                <div className="default-container">
                  {Loading ? (
                      <ImageLoadingSpinner message={loading} />
                    ) : selectedComponent === 'CreateDM' ? (
                      <div className="dm-page-container">
                      {Loading ? <ImageLoadingSpinner message="Generating your message..." /> : (
                        <>
                          <div className="dm-header-section">
                            <h1>DM Template Creation & Management</h1>
                          </div>
                          <div className="dm-template-section">
                            <div className="dm-template-section-in">
                              <HeaderIcon />
                              <h1>Build Your DM Template</h1>
                            </div>
                            <p>Here you can create the DM template, edit & refine. Then, using our chrome extension on LinkedIn, you can generate a DM by clicking on...
                              <Vector className="vector-icon"/>
                            </p>
                          </div>
                          <div className="dm-form">
                            <PencilIcon />
                            <input
                              type="text"
                              placeholder="Give a name to this DM template"
                              value={templateName}
                              onChange={(e) => setTemplateName(e.target.value)}
                            />
                            <button className="save-dm" onClick={handleSaveTemplate}>Save DM</button>
                          </div>
                          <div className="grid-section">
                            {DirectMessageComponent}
                              <div className="left-section">
                              <div className="dm-content">
                                <div className="dm-template-preview">
                                  
                                  <div className="profile-header">
                                    <div className="profile-info">
                                      <span>{profileData.firstName} {profileData.lastName}</span>
                                    </div>
                                    <div className="profile-info">
                                      <CopyTemplate className="icon-copy" onClick={() => handleCopy(editableText)} />
                                      <EditTemplate className="icon-edit" onClick={toggleEditing} />
                                    </div>
                                  </div>
                                  {isEditing ? (
                                    <textarea
                                      ref={textareaRef}
                                      value={editableText}
                                      onChange={(e) => setEditableText(e.target.value)}
                                      onBlur={() => setIsEditing(false)}
                                      className="editable-textarea"
                                      style={{
                                        direction: isRtl(editableText) ? 'rtl' : 'ltr',
                                        textAlign: isRtl(editableText) ? 'right' : 'left'
                                      }}
                                    />
                                  ) : (
                                    <div
                                      className="dm-body"
                                      style={{
                                        direction: isRtl(editableText) ? 'rtl' : 'ltr',
                                        textAlign: isRtl(editableText) ? 'right' : 'left'
                                      }}
                                    >
                                      {editableText.split('\n').map((item, key) => (
                                        <span key={key}>{item}<br/></span>
                                      ))}
                                    </div>
                                  )}
                                  </div>
                                </div>
                              </div>
                              <div className="right-section">
              {/* Ensure progress-score-container is rendered only if scale is not undefined or null */}
              {scale != null && (
                <div className="progress-score-container">
                  <div className="chart-container">
                    <img src={linkedinIcon} alt="LinkedIn" className="chart-icon" />
                    {scale && renderChart(scale)}
                    <p className="scale-value">{scale}</p>
                  </div>
                  {/* <div className="score-buttons">
                    <button className="progress-button-regenerate">Re-generate DM</button>
                    <button className="progress-button-boost">Magic Boost</button>
                    <button className="progress-button-refine">Refine</button>
                  </div> */}
                </div>
              )}
              <DynamicParameters insertParameter={insertParameter} />
            </div>
          </div>
                          <div className="edit-request-section">
                            <input
                              type="text"
                              placeholder="Write here a request to edit the DM in a specific way"
                              className="edit-request-input"
                            />
                            <button className="edit-request-btn">
                              <div className="button-content">
                              <div className="top-content">
                                <RequestIconEdit />
                                <span>Request Edit</span>
                                </div>
                                <span className="coming-soon-text">Coming soon</span>
                              </div>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    ) : (
                      <>
                        <h1>No response yet.</h1>
                        <h1>Submit the form to see the result.</h1>
                        <img src={logo} alt="Logo" className="logo-image-default" />
                      </>
                  )}
                </div>
            )}
          </div>
        </div>
      </div>
  );
}

export default App;