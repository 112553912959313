import React, { useEffect, useState, useCallback } from "react";
import { API_URL } from "../config";
import "../styles/Dashboard.css";
import countries from "i18n-iso-countries";
import "i18n-iso-countries/langs/en.json";
import ImageLoadingSpinner from "./ImageLoadingSpinner";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Dashboard = ({ csrfToken, onTrendSelect }) => {
  const [trendsData, setTrendsData] = useState([]);
  const [data, setData] = useState({});
  const [isTrendsLoading, setIsTrendsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [error, setError] = useState(null);

  const [timeChoice, setTimeChoice] = useState("Past 7 days");
  const [country, setCountry] = useState("United States");
  const [category, setCategory] = useState("All categories");
  const [countrySuggestions, setCountrySuggestions] = useState([]);

  const TIME_CHOICES = ["Past 4 hours", "Past 24 hours", "Past 48 hours", "Past 7 days"];
  const CATEGORY_CHOICES = {
    "All categories": null,
    "Autos and Vehicles": 1,
    "Beauty and Fashion": 2,
    "Business and Finance": 3,
    "Climate": 4,
    "Entertainment": 5,
    "Food and Drink": 6,
    "Games": 7,
    "Health": 8,
    "Hobbies and Leisure": 9,
    "Jobs and Education": 10,
    "Law and Government": 11,
    "Other": 12,
    "Pets and Animals": 13,
    "Politics": 14,
    "Science": 15,
    "Shopping": 16,
    "Sports": 17,
    "Technology": 18,
    "Travel and Transportation": 19,
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No Data";
    try {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.error("Invalid date format:", dateString);
      return "Invalid Date";
    }
  };

  const fetchTrendsData = useCallback(async (pastTime, countryName, categoryName) => {
    const token = localStorage.getItem("access_token");
    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      return;
    }

    setIsTrendsLoading(true);

    try {
      const response = await fetch(`${API_URL}/google-trends/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({
          past_time: pastTime,
          sort_choices: "Relevance",
          country_name: countryName,
          category: categoryName !== "All categories" ? categoryName : null,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setTrendsData(responseData.trends || []);
      } else {
        console.error("Failed to fetch Google Trends data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Google Trends data:", error);
    } finally {
      setIsTrendsLoading(false);
    }
  }, [csrfToken]);

  const fetchLatestObjects = useCallback(async () => {
    const token = localStorage.getItem("access_token");

    if (!token || !csrfToken) {
      console.error("Missing required tokens: Authorization or CSRF.");
      setError("Loading...");
      setIsDataLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/marketing_content/api/latest-objects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
        setError(null);
      } else if (response.status === 401) {
        setError("Authorization failed. Please log in again.");
      } else {
        console.error("Failed to fetch data:", response.statusText);
        setError("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsDataLoading(false);
    }
  }, [csrfToken]);

  useEffect(() => {
    fetchTrendsData("Past 7 days", "United States", "All categories");
    fetchLatestObjects();
  }, [fetchTrendsData, fetchLatestObjects]);

  const handleCountryChange = (e) => {
    const input = e.target.value;
    setCountry(input);
    if (input.length > 1) {
      const suggestions = Object.values(countries.getNames("en"))
        .map((name) => name.split(" of ")[0]) // Trim long names like "United States of America"
        .filter((name) =>
          name.toLowerCase().startsWith(input.toLowerCase())
        );
      setCountrySuggestions(suggestions);
    } else {
      setCountrySuggestions([]);
    }
  };

  const handleTrendsSearch = () => {
    fetchTrendsData(timeChoice, country, category);
  };

  const renderTrendsTable = () => (
    <div className="trends-section">
      <h3>Trends</h3>
      <div className="filters">
        <select value={timeChoice} onChange={(e) => setTimeChoice(e.target.value)}>
          {TIME_CHOICES.map((choice) => (
            <option key={choice} value={choice}>
              {choice}
            </option>
          ))}
        </select>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          {Object.keys(CATEGORY_CHOICES).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        <div className="autocomplete">
          <input
            type="text"
            value={country}
            onChange={handleCountryChange}
            placeholder="Enter country"
          />
          {countrySuggestions.length > 0 && (
            <ul className="suggestions">
              {countrySuggestions.map((suggestion) => (
                <li
                  key={suggestion}
                  onClick={() => {
                    setCountry(suggestion);
                    setCountrySuggestions([]);
                  }}
                  className="suggestion-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          className="search-button"
          onClick={handleTrendsSearch}
          disabled={isTrendsLoading}
        >
          Search
        </button>
      </div>
        {isTrendsLoading ? (
            <ImageLoadingSpinner message="Loading Trends" />
        ) : (
        <table className="documents-table">
          <thead>
            <tr>
              <th>Trend</th>
              <th>Search Volume</th>
              <th>Started</th>
            </tr>
          </thead>
          <tbody>
            {trendsData.map((trend, index) => (
              <tr key={index}>
                <td
                    onClick={() => onTrendSelect(trend.trend)}
                    style={{cursor: "pointer", color: "blue", textDecoration: "underline"}}
                >
                  {trend.trend}
                </td>
                <td>{trend.search_volume}</td>
                <td>{trend.started}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  const renderDocumentsTable = () => (
    <div className="documents-section">
      <h3>Recent Documents</h3>
      <table className="documents-table">
        <thead>
          <tr>
            <th>Recent Created Content</th>
            <th>Type</th>
            <th>Date Added</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((key) => {
            if (!data[key]) return null;
            const { content, date } = data[key];
            return (
              <tr key={key}>
                <td>
                  <pre>{content || "No Content"}</pre>
                </td>
                <td>{key.replace(/_/g, " ")}</td>
                <td>{formatDate(date)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="dashboard-container">
      {renderTrendsTable()}
      {/*{!isDataLoading && renderDocumentsTable()}*/}
    </div>
  );
};

export default Dashboard;
