import React from 'react';
import classNames from 'classnames';
import SidebarItem from "./SidebarItem";

const SidebarTitle = ({title, isCollapsed, isDropdownOpen, isInverted=false, isStroke=false,
                          setComponent, selectedComponent, clickFunc, ImgComp, ImgAlt, items=[]}) => {

    // TODO Use Context API to avoid passing isCollapsed, setComponent and selectedComponent to all elements
    const isMenuActive = items.some(item => item.component === selectedComponent);

    return (
        <div>
            <div className={classNames("sidebar-title-with-icon", {'open-dropdown': isDropdownOpen && !isCollapsed, active: isMenuActive})} onClick={clickFunc}>
                <ImgComp alt={ImgAlt} className={classNames("sidebar-icon", {'inverted-icon': isInverted, 'stroke-icon': isStroke})}/>
                {!isCollapsed && (<span className="sidebar-title">{title}</span>)}
                {!isCollapsed && (<span className={isDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>)}
            </div>

            {!isCollapsed && isDropdownOpen && (
                <ul className="dropdown-menu">
                    {items.map((item, i) => (
                        <SidebarItem key={i} title={item.title} ImgAlt={item.ImgAlt} ImgComp={item.ImgComp}
                                     setComponent={setComponent} selectedComponent={selectedComponent} component={item.component}/>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SidebarTitle;