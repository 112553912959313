// templatecontext.js
import React, { createContext, useContext, useState } from 'react';

const TemplateContext = createContext();

export const TemplateProvider = ({ children }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [scale, setScale] = useState(null);  
    const [language, setLanguage] = useState('en'); // default to English
    const [Loading, setLoading] = useState(false); // Track loading state globally

    return (
        <TemplateContext.Provider value={{ selectedTemplate, setSelectedTemplate, language, setLanguage, Loading, setLoading,scale,setScale }}>
            {children}
        </TemplateContext.Provider>
    );
};

export const useTemplate = () => useContext(TemplateContext);
